.navbar {
	position: fixed;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: auto;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: hsla(0, 0%, 86.7%, 0);
	box-shadow: 0 -1px 6px -1px #000;
}

.icon-3 {
	display: block;
}

.icon-4 {
	display: block;
}

.slider-2 {
	height: 800px;
	background-color: rgba(0, 0, 0, 0.4);
}

.our-mission {
	background-color: #000;
	background-position: 0px 0px, 100% 0%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.image-slider {
	display: block;
	overflow: visible;
	margin-top: 0px;
}

.container {
	position: static;
	top: auto;
	z-index: auto;
	height: 65px;
	min-width: 100%;
	padding-right: 80px;
	padding-left: 80px;
	background-color: #fff;
}

.div-block {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 1000px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.div-block-2 {
	display: block;
	width: 34%;
	margin-bottom: 350px;
	margin-left: 100px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
	color: transparent;
}

.slider-heading {
	margin-top: 0px;
	padding-right: 0px;
	padding-left: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 50px;
	line-height: 58px;
}

.paragraph {
	margin-bottom: 20px;
	padding-right: 0px;
	padding-left: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 20px;
	line-height: 25px;
	font-weight: 500;
}

.video-section {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: visible;
	height: auto;
	margin-right: 10%;
	margin-bottom: 0px;
	margin-left: 10%;
	padding: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.nav-menu {
	display: flex;
	height: auto;
	margin-top: -48px !important;
	width: 100%;
	justify-content: flex-end;
}

.image {
	width: auto;
	height: 75px;
	margin-top: -27px;
}

.div-block-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 39%;
	height: auto;
	max-width: 50%;
	margin: 200px auto auto 0px;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
}

.providing-a-foundation {
	background-position: 0px 0px, 50% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.bold-paragraph {
	position: relative;
	left: 0%;
	top: 0%;
	right: 0%;
	bottom: auto;
	z-index: auto;
	width: 75%;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	padding: 0px;
	border-top-left-radius: 90px;
	border-bottom-left-radius: 90px;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	text-align: center;
}

.call-out {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: visible;
	height: auto;
	min-height: auto;
	margin: 80px 10%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.body {
	overflow: visible;
	color: #2b2954;
	font-size: 14px;
}

.utility-page-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100vw;
	height: 100vh;
	max-height: 100%;
	max-width: 100%;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.utility-page-content {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 260px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	text-align: center;
}

.utility-page-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.primary-button {
	margin-right: 10px;
	margin-left: 0px;
	padding-top: 10px;
	padding-bottom: 10px;
	border-radius: 20px;
	background-color: #2b2954;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: Montserrat, sans-serif;
	color: #fff;
	font-weight: 700;
}

.primary-button:hover {
	background-color: #4d5c79;
	color: #fff;
}

.slide-nav {
	display: block;
}

.helping-each-other {
	background-position: 0px 0px, 0% 50%;
	background-size: auto, cover;
	background-repeat: repeat, no-repeat;
}

.icon-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: visible;
	height: auto;
	margin-top: 100px;
	margin-bottom: 0px;
	padding-right: 10%;
	padding-bottom: 60px;
	padding-left: 10%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #fff;
}

.call-out-heading {
	width: 100%;
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	padding-left: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #4d5c79;
	font-size: 35px;
	line-height: 40px;
	font-weight: 700;
	text-align: left;
	text-transform: capitalize;
}

.section-3 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: auto;
	margin-top: 0px;
	padding: 60px 13px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: #4d5c79;
}

.div-block-10 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30%;
	height: auto;
	margin-top: 20px;
	margin-bottom: 20px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: 'Open Sans', sans-serif;
}

.text-block-6 {
	width: auto;
	color: #dbdbdb;
	text-align: left;
}

.phone-number {
	display: none;
	color: #dbdbdb;
	text-decoration: none;
}

.link-list-title {
	margin-right: 117px;
	margin-bottom: 0.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	text-align: left;
}

.list-link-wrapper {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.footer-list-link {
	margin-bottom: 0.35rem;
	padding-right: 0.25rem;
	padding-bottom: 0rem;
	padding-left: 0.35rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	border-bottom: 1px none hsla(0, 0%, 54.5%, 0.25);
	border-left: 3px solid transparent;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #dbdbdb;
	line-height: 1.3rem;
	text-align: center;
	text-decoration: none;
}

.footer-list-link:hover {
	border-bottom-color: transparent;
	border-left-color: transparent;
	color: #fff;
}

.link-list-title-2 {
	margin-bottom: 0.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	text-align: center;
}

.grip-logo-image-2 {
	width: 10rem;
	min-width: 150px;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
}

.section-4 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 220px;
	padding-left: 220px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #3e4b64;
	line-height: 70px;
}

.copyright-info {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.footer-copyright {
	display: inline-block;
	margin-right: 1.5rem;
	margin-bottom: 0rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 0.9rem;
}

.image-3 {
	width: 200px;
}

.icon-blocks {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 30%;
	margin-right: 0px;
	margin-left: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.purple-heading-2 {
	margin-top: -12px;
	font-family: 'Open Sans', sans-serif;
	color: #4d5c79;
	font-size: 25px;
	width: 100%;
	text-align: center;
}

.icon-paragraph {
	width: 80%;
	font-family: 'Open Sans', sans-serif;
	color: #242424;
	font-size: 17px;
	line-height: 24px;
	text-align: center;
}

.section-5 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: visible;
	height: auto;
	margin: 0px 10% 147px;
	padding-right: 0px;
	padding-left: 0px;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.slide-over-nav {
	-webkit-align-self: auto;
	-ms-flex-item-align: auto;
	-ms-grid-row-align: auto;
	align-self: auto;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #4d5c79;
	font-size: 17px;
	margin-left: 0px;
	margin-right: 0px;
	font-weight: 700;
}

.slide-over-nav:hover a {
	margin-top: 0px;
	border-style: solid solid none;
	border-width: 0px 0px 5px;
	border-color: #000 #000 #2b2954;
	box-shadow: 0 5px 0 0 #2b2954;
	color: #4d5c79;
	text-decoration: none;
}

.slide-over-nav.w--current {
	box-shadow: 0 4px 0 0 #2b2954;
	color: #2b2954;
}

.main-paragraph {
	width: 100%;
	margin-top: 10px;
	margin-left: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #242424;
	font-size: 20px;
	line-height: 30px;
	text-align: left;
}

.main-paragraph-copy {
	width: 100%;
	margin-bottom: 20px;
	margin-left: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #242424;
	font-size: 20px;
	line-height: 30px;
	text-align: right;
}

.image-6 {
	width: 45%;
	margin-bottom: 21px;
	margin-left: 0px;
}

.div-block-22 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 43%;
	margin-right: 0px;
	margin-left: 0px;
	padding-right: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.right-aligned-heading {
	width: 100%;
	margin-top: 0px;
	margin-bottom: 10px;
	margin-left: 0px;
	padding-left: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #4d5c79;
	font-size: 35px;
	line-height: 40px;
	font-weight: 700;
	text-align: right;
	text-transform: capitalize;
}

.left-aligned-heading {
	width: 100%;
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	padding-left: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #4d5c79;
	font-size: 30px;
	line-height: 35px;
	font-weight: 700;
	text-align: left;
	text-transform: capitalize;
}

.left-aligned-paragraph {
	width: auto;
	margin-top: 10px;
	margin-bottom: 20px;
	margin-left: 0px;
	font-family: 'Open Sans', sans-serif;
	color: #242424;
	font-size: 20px;
	line-height: 30px;
	text-align: left;
	width: 100%;
}

.vsg-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 48%;
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: auto;
	padding-right: 0px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
}

.text-block-7 {
	color: #4d5c79;
}

.icon-6 {
	color: #2b2954;
}

.dropdown-list {
	background-color: #4d5c79;
}

.dropdown-list.w--open {
	margin-top: 0px;
	margin-bottom: 0px;
	padding: 10px 0px;
	border-top: 5px solid #2b2954;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	background-color: #2b2954;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #2b2954;
}

.dropdown-link {
	width: 100%;
	padding-top: 10px;
	padding-bottom: 10px;
	color: #fff;
}

.dropdown-link:hover {
	width: auto;
	border-bottom: 1px none #000;
	background-color: transparent;
	color: silver;
	text-decoration: none;
}

.div-block-27 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 50%;
	height: auto;
	margin-top: 32px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-radius: 0%;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	opacity: 1;
}

.yellow-circle {
	position: static;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 250px;
	height: 250px;
	margin-right: 0px;
	margin-bottom: 0px;
	margin-left: -245px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: none;
	border-width: 3px;
	border-color: #2b2954;
	border-radius: 100%;
	background-color: #2b2954;
	color: #2b2954;
	z-index: 200;
}

.image-10 {
	width: auto;
	height: auto;
}

.image-10-copy {
	margin-top: 0px;
	margin-right: 0px;
	border-radius: 100%;
	width: 100%;
}

.dog-circle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 40%;
	height: auto;
	margin-top: 32px;
	margin-bottom: 0px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	border-radius: 100%;
	background-image: none;
	background-size: auto;
	background-repeat: repeat;
	opacity: 1;
}

.image-14 {
	display: none;
}

.image-15 {
	display: none;
}

.div-block-34 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 46%;
	margin-right: 0px;
	padding-right: 0px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.image-16 {
	width: auto;
	height: auto;
	max-width: 90%;
	margin: 0px;
	border-radius: 1100%;
}

.yellow-quote-circle {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 250px;
	height: 250px;
	margin: 404px -180px -40px -296px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: none;
	border-width: 3px;
	border-color: #2b2954;
	border-radius: 100%;
	background-color: #2b2954;
	z-index: 200;
}

.div-block-36 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-left: -145px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.lightbox-link-2 {
	width: 100%;
}

.call-out-heading-copy {
	width: 100%;
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: 0px;
	padding-right: 20px;
	padding-left: 20px;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 27px;
	line-height: 36px;
	font-weight: 700;
	text-align: center;
	text-transform: capitalize;
}

.div-block-37 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	padding-right: 80px;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: auto;
	-ms-flex-item-align: auto;
	align-self: auto;
	background-color: #fff;
}

.image-17 {
	width: 50px;
	margin-right: 0px;
	padding: 10px;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.image-18 {
	width: 50px;
	margin-left: 0px;
	padding: 10px;
}

.div-block-38 {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: auto;
	margin-top: 10px;
	margin-right: 110px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.social-icon {
	margin-right: 10px;
	border-radius: 100%;
}

.social-icon:hover {
	background-color: hsla(0, 0%, 100%, 0.22);
}

.social-icon-end {
	margin-right: 0px;
	border-radius: 100%;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
}

.social-icon-end:hover {
	background-color: hsla(0, 0%, 100%, 0.22);
}

.div-block-2-copy {
	display: none;
	width: 34%;
	margin-bottom: 350px;
	margin-left: 100px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	background-color: transparent;
	color: transparent;
}

.slide-over-nav-copy {
	margin-right: 10px;
	margin-left: 0px;
	-webkit-align-self: auto;
	-ms-flex-item-align: auto;
	-ms-grid-row-align: auto;
	align-self: auto;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	color: #4d5c79;
	line-height: 5px;
	font-weight: 700;
}

.slide-over-nav-copy:hover {
	margin-top: 0px;
	border-style: solid solid none;
	border-width: 0px 0px 5px;
	border-color: #000 #000 #2b2954;
	box-shadow: 0 4px 0 0 #2b2954;
	color: #4d5c79;
	text-decoration: none;
}

.link-list-title-copy {
	margin-right: 110px;
	margin-bottom: 0.5rem;
	font-family: 'Open Sans', sans-serif;
	color: #fff;
	font-size: 18px;
	font-weight: 700;
	text-align: left;
}

.list {
	line-height: 22px;
}

.paragraph-13 {
	line-height: 22px;
}

.left-nav-li.grandchild-li {
	margin-left: 0.75rem;
}

.left-nav-li.greatgrandchild-li {
	margin-top: 2px;
	margin-left: 0.85rem;
	font-size: 0.9rem;
}

.breadcrumbs-list {
	margin-bottom: 1rem;
	padding-left: 0px;
	color: #fff;
}

.heading-20 {
	color: #4d5c79;
}

.main-content-outer-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.left-nav-section-title {
	margin-top: 0px;
	border-bottom: 1px none rgba(0, 0, 0, 0.15);
}

.breadcrumbs-wrapper {
	display: block;
	color: #424242;
	font-size: 0.8rem;
	line-height: 1.5rem;
	font-weight: 400;
	text-decoration: none;
}

.text-link {
	border-bottom: 1px solid #2b2954;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #2b2954;
	line-height: 20px;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

.text-link:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #4d5c79;
}

.left-nav-col {
	width: 20%;
	height: 100%;
	max-width: none;
	min-height: auto;
	min-width: auto;
	margin-top: 0px;
	margin-right: 3vw;
	padding-top: 0rem;
	padding-right: 0.75px;
	padding-bottom: 1rem;
	float: left;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	border-right: 3px none #e41356;
	background-color: transparent;
	font-family: 'Open Sans', sans-serif;
}

.main-content {
	display: block;
	width: 100%;
	padding-top: 0rem;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
}

.inside-content-sect {
	position: relative;
	display: block;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	padding-right: 0vw;
	padding-left: 0vw;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	background-color: #fff;
}

.left-nav-link-3 {
	display: block;
	width: 100%;
	margin-bottom: 0.5rem;
	padding: 2px 0rem 2px 0.63rem;
	border-left: 2px none transparent;
	border-radius: 0px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #4d5c79;
	font-size: 1rem;
	line-height: 1.6rem;
	font-weight: 600;
	text-decoration: none;
	text-transform: none;
	border-bottom: 0px;
}

.left-nav-link-3:hover {
	width: 100%;
	padding-right: 0rem;
	padding-left: 0.63rem;
	border-left-style: none;
	border-left-color: #e41356;
	background-color: transparent;
	color: #a7a7a7;
}

.left-nav-link-3.w--current {
	border-left-color: #c2d82e;
	border-radius: 0px;
	background-color: rgba(0, 0, 0, 0.07);
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	color: #4d5c79;
	text-decoration: none;
	text-transform: none;
}

.left-nav-link-3.w--current:hover {
	background-color: rgba(0, 0, 0, 0.07);
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.left-nav-link-3.grandchild-link {
	width: 100%;
	padding-top: 1px;
	padding-bottom: 1px;
	border-left-color: transparent;
	border-radius: 0px;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-size: 0.9rem;
	line-height: 1.5rem;
	font-weight: 600;
	text-transform: none;
}

.left-nav-link-3.grandchild-link:hover {
	width: 100%;
	border-left-color: #e41356;
	background-color: transparent;
}

.left-nav-link-3.grandchild-link.w--current {
	border-left-color: #c2d82e;
	border-radius: 0px;
	background-image: none;
	color: #4d5c79;
	font-weight: 600;
}

.left-nav-link-3.grandchild-link.w--current:hover {
	background-color: rgba(0, 0, 0, 0.07);
}

.left-nav-link-3.greatgrandchild-link {
	width: 100%;
	padding-top: 1px;
	padding-bottom: 1px;
	border-left-color: transparent;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	font-size: 0.8rem;
	line-height: 1.3rem;
	font-weight: 400;
	text-transform: none;
}

.left-nav-link-3.greatgrandchild-link:hover {
	width: 100%;
	border-left-color: #e41356;
	box-shadow: none;
	color: #a7a7a7;
}

.left-nav-link-3.greatgrandchild-link.w--current {
	border-left-color: #c2d82e;
	background-image: none;
	-webkit-transition-duration: 450ms;
	transition-duration: 450ms;
	color: #4d5c79;
	font-size: 0.83rem;
	line-height: 1.13rem;
	font-weight: 500;
}

.left-nav-ul {
	display: block;
	margin-bottom: 0.5rem;
	background-color: transparent;
}

.h5-link {
	height: auto;
	border: 1px none #000;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #2b2954;
	font-size: 14px;
	line-height: 20px;
	text-decoration: underline;
	cursor: pointer;
}

.h5-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: rgba(43, 41, 84, 0.45);
	line-height: 6px;
}

.h4-link {
	height: auto;
	border: 1px none #000;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #2b2954;
	font-size: 18px;
	line-height: 24px;
	text-decoration: underline;
	cursor: pointer;
}

.h4-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: rgba(43, 41, 84, 0.45);
	line-height: 6px;
}

.left-nav-section-title-link,
.left-nav-section-title-link:hover {
	display: block;
	margin-bottom: 5px;
	padding: 10px;
	border-style: none;
	border-width: 2px;
	border-color: #000 #000 #2b2954;
	border-radius: 0px;
	background-color: #4d5c79;
	color: #fff;
	font-size: 25px;
	line-height: 30px;
	font-weight: 700;
	text-decoration: none;
}

.main-content-inner-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 102px;
	width: 100%;
	padding-top: 2rem;
	font-family: Montserrat, sans-serif;
	min-height: 600px;
	justify-content: center;
}

.heading-16 {
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: 0%;
	padding: 0px 0px 10px;
	border-radius: 20px;
	background-color: transparent;
	color: #4d5c79;
	font-size: 50px;
	line-height: 55px;
	font-weight: 700;
	text-transform: none;
}

.horiz-divider {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(0, 0, 0, 0.15);
}

.breadcrumb-link {
	border-bottom: 1px solid #2b2954;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #4d5c79;
	font-size: 0.8rem;
	line-height: 1.5rem;
	text-decoration: none;
	cursor: pointer;
}

.breadcrumb-link:hover {
	border-bottom-color: transparent;
	color: #2b2954;
}

.breadcrumb-link.current-bc-link {
	border-bottom-style: none;
	color: #2b2954;
}

.breadcrumb-link.current-bc-link:hover {
	color: #424242;
}

.heading-2-link {
	height: auto;
	border-style: none;
	border-width: 1px 1px 2px;
	border-color: #000 #000 #9492c3;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #2b2954;
	font-size: 32px;
	line-height: 25px;
	text-decoration: underline;
	cursor: pointer;
}

.heading-2-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: rgba(43, 41, 84, 0.45);
	line-height: 6px;
}

.block-quote {
	margin-bottom: 1rem;
	padding: 1rem 1.5rem;
	border-left-width: 9px;
	border-left-color: #4d5c79;
	background-color: #e7e7e7;
	color: #404040;
	font-size: 1.35rem;
	line-height: 2rem;
}

.left-nav-wrapper {
	height: auto;
	margin-top: 0px;
	margin-right: 0;
	margin-left: 0rem;
	padding-right: 0;
	padding-left: 0.5rem;
	border-right: 1px none rgba(228, 19, 86, 0.59);
	width: 100%;
	max-width: 227px;
}

.container-3 {
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.container-3.inside-page-container {
	max-width: 1200px;
}

.h6-link {
	height: auto;
	border: 1px none #000;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #2b2954;
	font-size: 12px;
	line-height: 18px;
	text-decoration: underline;
	cursor: pointer;
}

.h6-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: rgba(43, 41, 84, 0.45);
	line-height: 6px;
}

.button-5 {
	display: inline-block;
	min-width: 100px;
	margin-top: 16px;
	margin-right: 16px;
	margin-bottom: 16px;
	padding: 0.8rem 1rem 0.7rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	-ms-grid-row-align: center;
	align-self: center;
	border-style: none;
	border-width: 2px;
	border-color: #6bb745;
	border-radius: 50px;
	background-color: #2b2954;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1rem;
	font-weight: 700;
	text-align: center;
	text-decoration: none;
	text-transform: none;
	cursor: pointer;
}

.button-5:hover {
	border-style: none;
	border-width: 2px;
	border-color: #ffd100;
	background-color: #4d5c79;
	color: #fff;
}

.button-5.tertiary {
	min-width: 60px;
	margin-top: 0.5rem;
	margin-right: 0rem;
	margin-bottom: 0.5rem;
	padding: 0.25rem 0.75rem;
	border: 1px solid #c4ccd1;
	background-color: transparent;
	color: #4d5c79;
	font-size: 0.9rem;
	line-height: 1.4rem;
}

.button-5.tertiary:hover {
	background-color: #f1f1f1;
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
	color: #2b2954;
}

.button-5.secondary {
	border-style: none;
	border-color: #ffd100;
	background-color: #4d5c79;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	text-align: center;
	cursor: pointer;
}

.button-5.secondary:hover {
	border-color: #005e9e;
	background-color: #707b8f;
	background-image: none;
	opacity: 1;
	color: #fff;
}

.ordered-list {
	padding-left: 20px;
	line-height: 22px;
}

.h3-link {
	height: auto;
	border: 1px none #000;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #2b2954;
	font-size: 24px;
	line-height: 30px;
	text-decoration: underline;
	cursor: pointer;
}

.h3-link:hover {
	border-bottom-style: none;
	border-bottom-color: transparent;
	color: rgba(43, 41, 84, 0.45);
	line-height: 6px;
	text-decoration: underline;
}

.intro-paragraph {
	margin-bottom: 1.5rem;
	color: #424242;
	font-size: 1.75rem;
	line-height: 2.25rem;
}

.breadcrumbs-list-item {
	display: inline-block;
	float: left;
}

.breadcrumb-divider {
	display: inline-block;
	margin-right: 0.5rem;
	margin-left: 0.5rem;
	color: #4d5c79;
	font-size: 1rem;
}

.content-outer-wrap {
	position: relative;
	z-index: 2;
	display: block;
	height: auto;
	margin-top: 0px;
	margin-right: auto;
	margin-left: auto;
	padding-bottom: 4rem;
	border-top: 8px none #629edc;
}

.div-block-39 {
	font-family: 'Open Sans', sans-serif;
}

.no-sidebars .div-block-39 {
	width: 80%;
}

.right-side-col {
	position: relative;
	width: 20%;
	max-width: 250px;
	min-width: 160px;
	margin-left: 3vw;
	padding-top: 1rem;
	-webkit-box-flex: 0;
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	font-family: 'Open Sans', sans-serif;
}

.right-col-item-wrap {
	padding-bottom: 0.4rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.h2-right-column-title {
	margin-top: 0px;
	color: #4d5c79;
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
}

.h3-right-column-title {
	color: #4d5c79;
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
}

.h4-right-column-title {
	color: #4d5c79;
}

.yellow-circle-copy {
	position: absolute;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 250px;
	height: 250px;
	margin: 81px 0px 0px 49px;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-style: none;
	border-width: 3px;
	border-color: #2b2954;
	border-radius: 100%;
	background-color: #2b2954;
	color: #2b2954;
}

@media all and (-ms-high-contrast: none) {
	.yellow-circle-copy {
		bottom: 0px;
		right: 49px;
	} /* IE10 */
	*::-ms-backdrop,
	.yellow-circle-copy {
		bottom: 0px;
		right: 49px;
	} /* IE11 */
}

.link {
	color: #c9cbcf;
	text-decoration: none;
}

@media (max-width: 991px) {
	.navbar {
		height: auto;
	}

	.icon-3 {
		font-size: 25px;
	}

	.icon-4 {
		font-size: 25px;
	}

	.slider-2 {
		height: 400px;
	}

	.our-mission {
		background-position: 50% 0%;
	}

	.image-slider {
		margin-top: 111px;
	}

	.container {
		padding-right: 20px;
		padding-left: 20px;
	}

	.div-block {
		height: 400px;
	}

	.div-block-2 {
		width: 45%;
		margin-bottom: 60px;
		margin-left: 40px;
		font-size: 14px;
	}

	.slider-heading {
		padding-right: 0px;
		padding-left: 0px;
		font-size: 40px;
		line-height: 48px;
	}

	.paragraph {
		padding-right: 0px;
		padding-left: 0px;
		font-size: 15px;
		line-height: 22px;
	}

	.video-section {
		height: auto;
		padding-right: 100px;
		padding-left: 100px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.nav-menu {
		margin-top: 111px;
		background-color: #4d5c79;
	}

	.image {
		height: 80px;
		margin-bottom: 10px;
	}

	.div-block-3 {
		width: 90%;
		max-width: none;
		margin: 60px 0px 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.providing-a-foundation {
		background-position: 0px 0px, 50% 50%;
	}

	.bold-paragraph {
		width: 70%;
		font-size: 12px;
		line-height: 18px;
	}

	.call-out {
		height: auto;
		min-height: auto;
		margin: 0px 60px 186px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		background-image: none;
	}

	.primary-button {
		margin-left: 0px;
	}

	.helping-each-other {
		background-position: 0px 0px, 42% 50%;
	}

	.menu-button.w--open {
		background-color: transparent;
	}

	.icon-row {
		height: auto;
		margin-top: 60px;
		padding: 60px 100px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.call-out-heading {
		width: auto;
		margin-bottom: 11px;
		font-size: 30px;
		line-height: 40px;
		text-align: center;
	}

	.section-3 {
		padding: 40px 0.125em;
	}

	.text-block-6 {
		width: 100%;
		font-size: 12px;
	}

	.link-list-title {
		width: 100%;
		margin-right: 0px;
		padding-left: 15px;
		font-size: 16px;
	}

	.footer-list-link {
		font-size: 12px;
		text-align: center;
	}

	.link-list-title-2 {
		font-size: 16px;
	}

	.grip-logo-image-2 {
		width: 6rem;
	}

	.section-4 {
		padding-right: 60px;
		padding-left: 60px;
	}

	.footer-copyright {
		margin-right: 0rem;
		margin-bottom: 0.25rem;
		float: left;
		font-size: 0.7rem;
	}

	.image-3 {
		width: 170px;
	}

	.icon-blocks {
		width: 90%;
		padding-bottom: 60px;
	}

	.purple-heading-2 {
		text-align: center;
	}

	.section-5 {
		width: 100%;
		margin-top: -39px;
		margin-right: 0%;
		margin-left: 0%;
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.slide-over-nav {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		color: #fff;
	}

	.slide-over-nav:hover {
		color: #2b2954;
	}

	.main-paragraph {
		width: 100%;
		font-size: 15px;
		text-align: center;
	}

	.main-paragraph-copy {
		width: 464px;
		text-align: center;
	}

	.image-6 {
		width: 70%;
		margin-bottom: -13px;
	}

	.div-block-22 {
		width: 80%;
		margin: 161px 0px -1041px;
		padding-right: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.right-aligned-heading {
		margin-bottom: 0px;
		font-size: 26px;
		line-height: 40px;
		text-align: center;
	}

	.left-aligned-heading {
		width: auto;
		margin-bottom: 7px;
		font-size: 26px;
		line-height: 34px;
		text-align: center;
	}

	.left-aligned-paragraph {
		width: 100%;
		text-align: center;
	}

	.vsg-container {
		width: 80%;
		margin-top: 0px;
		margin-bottom: 198px;
		margin-left: 0px;
		padding-right: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.icon-5 {
		color: #4d5c79;
		font-size: 28px;
	}

	.icon-5:active {
		color: #4d5c79;
	}

	.text-block-7 {
		color: #fff;
		text-align: center;
	}

	.div-block-27 {
		width: auto;
		height: auto;
		margin-top: 40px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.yellow-circle {
		width: 250px;
		height: 250px;
		margin-right: 0px;
		margin-left: -250px;
		padding-right: 0px;
	}

	.image-10 {
		width: auto;
		height: auto;
		margin-right: 0px;
	}

	.image-10-copy {
		width: 100%;
		margin-top: -35px;
		margin-right: 0px;
	}

	.dog-circle {
		width: 80%;
		height: auto;
		margin-top: -21px;
		margin-bottom: 100px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.image-14 {
		display: none;
	}

	.image-15 {
		display: none;
		overflow: visible;
	}

	.left-arrow {
		width: 49px;
	}

	.right-arrow {
		width: 49px;
	}

	.div-block-34 {
		width: 100%;
		height: auto;
		margin: 75px 0px 0px;
	}

	.image-16 {
		height: auto;
	}

	.yellow-quote-circle {
		width: 250px;
		height: 250px;
		margin-right: 0px;
		margin-left: -250px;
		padding-right: 0px;
	}

	.div-block-36 {
		margin-left: -18px;
	}

	.call-out-heading-copy {
		width: 100%;
		margin-bottom: 11px;
		font-size: 30px;
		line-height: 40px;
		text-align: center;
	}

	.div-block-37 {
		padding-right: 0px;
		color: #4d5c79;
	}

	.div-block-38 {
		width: 100%;
		margin-right: 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.div-block-2-copy {
		width: 45%;
		margin-bottom: 60px;
		margin-left: 40px;
		font-size: 14px;
	}

	.slide-over-nav-copy {
		color: #4d5c79;
	}

	.slide-over-nav-copy:hover {
		color: #4d5c79;
	}

	.link-list-title-copy {
		width: auto;
		margin-right: 0px;
		font-size: 16px;
	}

	.main-content-outer-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.5rem;
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	.left-nav-col {
		display: none;
	}

	.main-content {
		width: 100%;
		padding-top: 1rem;
	}

	.main-content-inner-wrap {
		display: block;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.breadcrumb-link {
		margin-top: 0.25rem;
	}

	.container-3 {
		max-width: 768px;
	}

	.intro-paragraph {
		font-size: 1.65rem;
		line-height: 2.35rem;
	}

	.content-outer-wrap {
		height: 100%;
		padding-top: 0rem;
		padding-right: 2rem;
		padding-left: 2rem;
	}

	.right-side-col {
		width: 100%;
		max-width: none;
		min-width: 0px;
		margin-top: 2rem;
		margin-left: 0rem;
		padding-top: 0rem;
		border-top: 1px solid rgba(0, 0, 0, 0.15);
	}

	.yellow-circle-copy {
		width: 250px;
		height: 250px;
		margin-right: 0px;
		margin-left: -250px;
		padding-right: 0px;
	}
}

@media all and (-ms-high-contrast: none) and (max-width: 991px) {
	.yellow-circle-copy {
		bottom: 0px;
		right: 70%;
	} /* IE10 */
	*::-ms-backdrop,
	.yellow-circle-copy {
		bottom: 0px;
		right: 70%;
	} /* IE11 */
}

@media (max-width: 767px) {
	.icon-3 {
		font-size: 22px;
	}

	.icon-4 {
		font-size: 22px;
	}

	.slider-2 {
		height: 400px;
	}

	.our-mission {
		height: 400px;
		background-position: 0px 0px, 50% 50%;
	}

	.image-slider {
		margin-top: 108px;
	}

	.container {
		padding-left: 20px;
	}

	.div-block {
		height: 400px;
	}

	.div-block-2 {
		width: 60%;
		margin-bottom: 42px;
		margin-left: 60px;
	}

	.slider-heading {
		font-size: 25px;
		line-height: 31px;
	}

	.paragraph {
		margin-bottom: 10px;
		padding-right: 0px;
		line-height: 20px;
	}

	.video-section {
		padding-right: 0px;
		padding-left: 0px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.nav-menu {
		margin-top: 110px;
	}

	.image {
		height: 80px;
	}

	.div-block-3 {
		width: 80%;
		max-width: none;
		margin-top: 66px;
		margin-bottom: 25px;
		margin-left: 0px;
	}

	.providing-a-foundation {
		background-position: 0px 0px, 50% 50%;
	}

	.bold-paragraph {
		width: 100%;
	}

	.call-out {
		height: auto;
		margin-right: 0px;
		margin-bottom: 0px;
		margin-left: 0px;
		background-image: none;
	}

	.primary-button {
		font-size: 11px;
	}

	.helping-each-other {
		background-position: 0px 0px, 51% 50%;
	}

	.menu-button {
		margin-top: 0px;
	}

	.menu-button.w--open {
		background-color: transparent;
	}

	.icon-row {
		margin-top: 0px;
		padding-right: 60px;
		padding-left: 60px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		padding-top: 0px;
	}

	.call-out-heading {
		font-size: 21px;
		line-height: 31px;
	}

	.section-3 {
		padding: 40px 2em;
	}

	.div-block-10 {
		width: 44%;
	}

	.text-block-6 {
		margin-bottom: 20px;
	}

	.link-list-title {
		margin-right: 0px;
		padding-left: 0px;
		font-size: 16px;
	}

	.footer-list-link {
		text-align: center;
	}

	.grip-logo-image-2 {
		width: 7.7rem;
		min-width: auto;
	}

	.copyright-info {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-copyright {
		float: none;
		font-size: 0.8rem;
	}

	.section-5 {
		margin: 0px 0% 47px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.slide-over-nav {
		color: #fff;
	}

	.main-paragraph {
		width: 100%;
	}

	.main-paragraph-copy {
		text-align: center;
	}

	.image-6 {
		width: 50%;
		margin-top: 20px;
		margin-bottom: 0px;
	}

	.div-block-22 {
		width: 80%;
		margin-top: 40px;
		margin-right: 0px;
		margin-bottom: -818px;
		padding-top: 0px;
		text-align: center;
	}

	.right-aligned-heading {
		font-size: 21px;
		line-height: 31px;
		text-align: center;
	}

	.left-aligned-heading {
		width: 100%;
		font-size: 21px;
		line-height: 31px;
		text-align: center;
	}

	.left-aligned-paragraph {
		width: 100%;
		text-align: center;
	}

	.vsg-container {
		width: 90%;
		margin: 0px 20px 20px;
		padding-right: 0px;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.text-block-7 {
		color: #fff;
		text-align: center;
	}

	.div-block-27 {
		width: 100%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.yellow-circle {
		width: 80%;
		height: 150px;
		margin-top: -59px;
		margin-left: 0px;
		padding-right: 60px;
		padding-left: 60px;
		border-radius: 0%;
	}

	.image-10 {
		display: none;
	}

	.image-10-copy {
		display: none;
	}

	.dog-circle {
		width: 100%;
		margin-top: -51px;
		margin-bottom: 9px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		position: relative;
	}

	.div-block-31 {
		overflow: hidden;
		height: 300px;
	}

	.image-14 {
		display: block;
		margin-top: -123px;
	}

	.div-block-32 {
		overflow: hidden;
		width: 100%;
		height: 350px;
	}

	.image-15 {
		display: block;
		margin-top: -105px;
	}

	.left-arrow {
		width: 54px;
	}

	.right-arrow {
		width: 54px;
	}

	.div-block-34 {
		overflow: visible;
		height: auto;
		margin-top: 49px;
		margin-bottom: -202px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.image-16 {
		max-width: none;
		margin-top: -59px;
		border-radius: 0%;
	}

	.yellow-quote-circle {
		width: 80%;
		height: 100px;
		margin-top: -59px;
		margin-bottom: -1px;
		margin-left: 0px;
		padding-right: 60px;
		padding-left: 60px;
		border-radius: 0%;
	}

	.div-block-36 {
		overflow: hidden;
		height: 400px;
		margin-left: 0px;
	}

	.call-out-heading-copy {
		font-size: 21px;
		line-height: 31px;
	}

	.div-block-2-copy {
		width: 60%;
		margin-bottom: 42px;
		margin-left: 60px;
	}

	.slide-over-nav-copy {
		color: #4d5c79;
	}

	.link-list-title-copy {
		margin-right: 0px;
		font-size: 16px;
	}

	.breadcrumbs-list {
		margin-top: 1.3rem;
		margin-bottom: 0.2rem;
	}

	.main-content-outer-wrap {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.breadcrumbs-wrapper {
		margin-top: 0.25rem;
		font-size: 0.85rem;
	}

	.main-content-inner-wrap {
		padding-top: 0rem;
	}

	.container-3 {
		max-width: 550px;
	}

	.content-outer-wrap {
		padding-right: 1rem;
		padding-bottom: 2rem;
		padding-left: 1rem;
	}

	.yellow-circle-copy {
		width: 80%;
		height: 150px;
		margin-top: -59px;
		margin-bottom: 50px;
		margin-left: 0px;
		padding-right: 60px;
		padding-left: 60px;
		border-radius: 0%;
		margin: 0;
		padding: 0;
		top: 12%;
		bottom: auto;
		left: auto;
		right: auto;
	}
}


@media all and (-ms-high-contrast: none) and (max-width: 767px) {
	.yellow-circle-copy {
		left: 10%;
		bottom: 25%;
	} /* IE10 */
	*::-ms-backdrop,
	.yellow-circle-copy {
		left: 10%;
		bottom: 25%;
	} /* IE11 */
}

@media (max-width: 479px) {
	.navbar {
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.icon-3 {
		margin-bottom: 154px;
	}

	.icon-4 {
		margin-bottom: 154px;
	}

	.slider-2 {
		height: 300px;
	}

	.our-mission {
		background-position: 0px 0px, 50% 50%;
	}

	.image-slider {
		margin-top: 94px;
	}

	.container {
		height: 55px;
		padding-right: 0px;
		padding-left: 0px;
	}

	.div-block {
		height: 300px;
	}

	.div-block-2 {
		display: block;
		width: 90%;
		margin-bottom: 20px;
		margin-left: 20px;
		font-size: 12px;
	}

	.slider-heading {
		font-size: 22px;
		line-height: 24px;
	}

	.paragraph {
		padding-right: 0px;
		font-size: 12px;
		line-height: 16px;
	}

	.nav-menu {
		width: auto;
		margin-top: 100px;
	}

	.image {
		height: 60px;
		margin-top: -12px;
	}

	.div-block-3 {
		width: 100%;
		margin-top: 40px;
		margin-right: 0px;
		margin-bottom: 0px;
		padding-right: 20px;
		padding-left: 20px;
	}

	.bold-paragraph {
		font-size: 12px;
	}

	.call-out {
		height: auto;
		margin-bottom: -30px;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		background-image: none;
	}

	.primary-button {
		font-size: 12px;
		line-height: 15px;
	}

	.menu-button {
		margin-top: -11px;
	}

	.menu-button.w--open {
		background-color: transparent;
	}

	.icon-row {
		padding-right: 10px;
		padding-bottom: 40px;
		padding-left: 10px;
	}

	.call-out-heading {
		margin-bottom: 0px;
		font-size: 19px;
	}

	.section-3 {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.div-block-10 {
		width: 80%;
		margin-top: 10px;
		margin-bottom: 10px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.text-block-6 {
		margin-bottom: 0px;
		text-align: center;
	}

	.link-list-title {
		margin-right: 0px;
		text-align: center;
	}

	.list-link-wrapper {
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.footer-list-link:hover {
		border-left-color: transparent;
	}

	.section-4 {
		padding-right: 20px;
		padding-left: 20px;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.copyright-info {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-copyright {
		margin-bottom: 0rem;
		font-size: 0.6rem;
		text-align: left;
	}

	.image-3 {
		width: 120px;
	}

	.section-5 {
		margin-bottom: 40px;
	}

	.slide-over-nav {
		font-size: 12px;
	}

	.main-paragraph-copy {
		width: 100%;
		font-size: 15px;
	}

	.image-6 {
		width: 75%;
		margin-bottom: 15px;
	}

	.div-block-22 {
		margin-top: -181px;
		margin-bottom: -763px;
		padding-right: 0px;
	}

	.vsg-container {
		width: 100%;
		margin-top: -24px;
		margin-bottom: 0px;
		margin-left: 0px;
		padding-top: 3px;
		padding-right: 20px;
		padding-left: 20px;
	}

	.div-block-27 {
		margin-bottom: -69px;
	}

	.yellow-circle {
		height: 120px;
		margin-top: -47px;
		padding: 40px 20px;
	}

	.dog-circle {
		margin-top: 0px;
		margin-bottom: 0px;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.div-block-31 {
		margin-bottom: -16px;
	}

	.image-14 {
		margin-top: -26px;
	}

	.div-block-32 {
		width: 108%;
		height: 250px;
		margin-top: -64px;
		margin-bottom: 6px;
	}

	.image-15 {
		width: 100%;
		max-width: none;
		margin-top: -40px;
	}

	.div-block-34 {
		margin-top: -103px;
		margin-bottom: -323px;
	}

	.image-16 {
		margin-top: -30px;
	}

	.yellow-quote-circle {
		margin-top: -187px;
		margin-bottom: 61px;
		padding: 10px 20px;
	}

	.div-block-36 {
		margin-top: 0px;
	}

	.call-out-heading-copy {
		margin-bottom: 0px;
		font-size: 19px;
	}

	.div-block-2-copy {
		display: none;
		width: 90%;
		margin-bottom: 20px;
		margin-left: 20px;
		font-size: 12px;
	}

	.slide-over-nav-copy {
		margin-right: 0px;
		font-size: 12px;
	}

	.link-list-title-copy {
		margin-right: 0px;
	}

	.breadcrumbs-wrapper {
		margin-top: 0rem;
	}

	.main-content {
		padding-top: 0.75rem;
	}

	.inside-content-sect {
		padding-right: 0.75rem;
		padding-left: 0.75rem;
	}

	.container-3 {
		max-width: none;
	}

	.intro-paragraph {
		font-size: 1.45rem;
		line-height: 2.15rem;
	}

	.content-outer-wrap {
		padding-right: 0rem;
		padding-bottom: 2rem;
		padding-left: 0rem;
	}

	.yellow-circle-copy {
		height: 120px;
		margin-top: -47px;
		margin-bottom: 61px;
		padding: 40px 20px;
	}
}

@media all and (-ms-high-contrast: none) and (max-width: 479px) {
	.yellow-circle-copy {
		padding: 0px 20px;
	} /* IE10 */
	*::-ms-backdrop,
	.yellow-circle-copy {
		padding: 0px 20px;
	} /* IE11 */
}


/****************** Tablesaw ***********************/
/****************************************************/
table.styled.tablesaw th,
table.styled.tablesaw td {
	border-left: 0;
	border-top: 0;
}


@media (max-width: 50em) {
	table.styled.tablesaw th,
	table.styled.tablesaw td {
		border-right: 0;
	}
}

@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: none;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Webflow **********************/
/*************************************************/
@media (min-width: 992px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="ffa7f895-eaed-88f1-5f36-f7183bacb550"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="9bc83c81-495f-25bc-6cc5-3127c6e48f89"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="bfbce6eb-6e9b-9142-5b29-71f996bee09f"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="39f280be-f118-90b7-cf6b-132338821cc3"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="9fc715f5-1d23-33ae-011a-51c1c69fbf1c"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="2791ac33-db77-df9d-c8e3-fc3b1006e976"] {
		opacity: 0;
	}
}

@media (max-width: 991px) and (min-width: 768px) {
	html.w-mod-js:not(.w-mod-ix) [data-w-id="ffa7f895-eaed-88f1-5f36-f7183bacb550"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="9bc83c81-495f-25bc-6cc5-3127c6e48f89"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="bfbce6eb-6e9b-9142-5b29-71f996bee09f"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="39f280be-f118-90b7-cf6b-132338821cc3"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="9fc715f5-1d23-33ae-011a-51c1c69fbf1c"] {
		opacity: 0;
	}

	html.w-mod-js:not(.w-mod-ix) [data-w-id="2791ac33-db77-df9d-c8e3-fc3b1006e976"] {
		opacity: 0;
	}
}

/****************** Print ***********************/
/*************************************************/
#printHeader,
#printFooter {
	display: none;
}

/****************** Header ***********************/
/*************************************************/
.page-container {
	overflow: hidden;
	width: 100%;
}

.w-nav-brand,
.accesible-navigation-menu a,
.div-block-37 a {
	border-bottom: 0px;
}

.w-nav-brand {
	width: 282px;
}

.cms_form_button.primary.skip-to-main-content.sronly {
	padding: 0px;
	border: 0;
	margin: 0;
	background: none;
}

.cms_form_button.primary.skip-to-main-content.sronly:focus {
	text-indent: 0;
	width: auto;
	height: auto;
	box-shadow: 0 0 5px #000;
	z-index: 2000;
	padding: 0.5rem 1.25rem;
	text-decoration: none;
}

.header-login-div {
	height: 54px;
	margin-top: 0;
	display: flex;
	align-items: center;
}

.header-login-div .slide-over-nav-copy,
.header-login-div .user-name,
.header-login-div .user-password {
	padding: 20px;
	text-decoration: none;
}

.header-login-div .user-name {
	color: #333;
}

@media (max-width: 991px) {
	.header-login-div {
		align-items: center;
		justify-content: flex-end;
	}

	.div-block-37 {
		display: block;
		text-align: right;
	}

	.header-login-div .user-name {
		font-weight: bold;
		font-family: 'Open Sans', sans-serif;
	}

	.header-login-div span:last-child {
		display: none;
	}
}

@media (max-width: 479px) {
	.header-login-div .user-name {
		font-size: 12px;
	}
}

/************* Accesible Menu ******************/
/*************************************************/
@media (max-width: 991px) {
	.accesible-navigation-menu {
		display: none;
	}
}

.accesible-navigation-menu {
	width: 100%;
}

.accesible-navigation-menu ul {
	margin: 0px;
	padding: 0px;
}

.accesible-navigation-menu li.slide-over-nav {
	margin-top: 0px;
	margin-bottom: 0px;
}

.accesible-navigation-menu li.slide-over-nav.has-mega-menu {
	position: static;
}

.accesible-navigation-menu li a.nav-link-a {
	font-size: 17px;
	font-family: 'Open Sans', sans-serif;
	font-weight: 700;
	color: #4d5c79;
	display: inline-block;
	padding: 20px 20px 21px 20px;
	text-decoration: none;
}

.accesible-navigation-menu li.slide-over-nav.hasChildren a.nav-link-a {
	padding-right: 37px;
}

.accesible-navigation-menu li.hasChildren a.nav-link-a:after {
	content: "\e603";
	font-weight: normal;
	position: absolute;
	margin-left: 3px;
	font-family: 'webflow-icons' !important;
}


.accesible-navigation-menu li a.nav-link-a.active {
	border-style: solid solid none;
	border-width: 0px 0px 5px;
	border-color: #000 #000 #2b2954;
	box-shadow: 0 5px 0 0 #2b2954;
	color: #4d5c79;
	text-decoration: none;
}

.accesible-navigation-menu li.slide-over-nav .container-wrapper {
	opacity: 0;
	max-height: 0px;
	position: absolute;
	top: 100%;
	z-index: 100;
	left: 0;
	transition: max-height 100ms ease 0s, opacity 200ms ease 0s;
	height: 0;
}

.accesible-navigation-menu li.slide-over-nav .container-wrapper ul {
	display: none;
	margin: 0px;
	list-style-type: none;
	min-width: 200px;
	padding: 10px 0px;
	border-top: 5px solid #2b2954;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
	background-color: #2b2954;
}

.accesible-navigation-menu li.slide-over-nav .container-wrapper ul li {
	display: inline-block;
	width: 100%;
}

.accesible-navigation-menu li.slide-over-nav .container-wrapper ul a {
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 20px;
	padding-left: 20px;
	color: #fff;
	text-decoration: none;
	display: inline-block;
	width: 100%;
}

.accesible-navigation-menu li.slide-over-nav .container-wrapper ul a:hover,
.accesible-navigation-menu li.slide-over-nav.container-wrapper ul a.w--current {
	color: silver;
}

.accesible-navigation-menu li.slide-over-nav.open .container-wrapper,
.accesible-navigation-menu li.slide-over-nav:hover .container-wrapper {
	opacity: 1;
	max-height: 500px;
	height: auto;
	border-top: 4px solid transparent;
}

.accesible-navigation-menu li.slide-over-nav.open.has-mega-menu .container-wrapper,
.accesible-navigation-menu li.slide-over-nav.has-mega-menu:hover .container-wrapper {
	width: 100%;
}

.accesible-navigation-menu li.slide-over-nav.open .container-wrapper ul,
.accesible-navigation-menu li.slide-over-nav:hover .container-wrapper ul {
	display: inline-block;
}

.accesible-navigation-menu li.slide-over-nav.has-mega-menu.open ul,
.accesible-navigation-menu li.slide-over-nav.has-mega-menu:hover .container-wrapper ul {
	display: flex;
	flex-wrap: wrap;
	width: 100%;
}

.accesible-navigation-menu li.slide-over-nav.has-mega-menu .container-wrapper ul li {
	max-width: 250px;
}

.accesible-navigation-menu li.slide-over-nav.has-mega-menu .container-wrapper ul ul {
	margin: 0px;
	padding: 0px;
	border: 0px;
	margin-top: -7px;
}

.accesible-navigation-menu li.slide-over-nav.has-mega-menu .container-wrapper ul ul li a {
	padding-top: 0px;
	padding-bottom: 5px;
	font-size: 0.9rem;
	line-height: 0.9rem;
	color: #ccc;
	font-weight: normal;
}

.accesible-navigation-menu li.slide-over-nav.has-mega-menu .container-wrapper ul ul li a:hover {
	color: #fff;
}

/************* Mobile Navigaton ******************/
/*************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-menu_opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	background-color: #4d5c79;
}

.mobile-navigation-menu li a.nav-a {
	color: #fff;
	transition: all 200ms ease;
	font-family: 'Open Sans', sans-serif;
	font-size: 17px;
	font-weight: 700;
	padding-top: 15px;
	padding-bottom: 15px;
}

.mobile-navigation-menu li:hover a.nav-a,
.mobile-navigation-menu li.active a.nav-a {
	background-color: rgba(255, 255, 255, 0.4);
}

.mobile-navigation-menu .mm-listitem:after {
	border-bottom: 0px;
}

.mobile-navigation-menu.mm-menu .mm-listview .mm-btn_next:after,
.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: #fff;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	color: #fff;
	font-family: 'Open Sans', sans-serif;
	font-size: 17px;
	font-weight: 700;
	text-align: left;
}

.mm-navbar {
	height: 50px;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: -10px;
}

.mm-navbar__title {
	padding-top: 14px;
}

.mm-btn_prev:before {
	left: 19px;
	top: 8px;
}

/********************** Homepage ********************/
/****************************************************/
.call-out-heading a,
.call-out-heading a:hover,
.purple-heading-2 a,
.purple-heading-2 a:hover,
.left-aligned-heading a,
.left-aligned-heading a:hover,
.call-out-heading-copy p,
.call-out-heading-copy li,
.call-out-heading-copy a,
.call-out-heading-copy a:hover,
.left-aligned-paragraph p,
.main-paragraph-copy p,
.right-aligned-heading a,
.right-aligned-heading a:hover,
.purple-heading-2 a,
.purple-heading-2 a:hover,
.icon-paragraph p,
.icon-paragraph li,
.main-paragraph p,
.main-paragraph li,
.main-paragraph a,
.bold-paragraph p {
	text-decoration: none;
	border-bottom: 0px;
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-size: inherit;
	font-family: inherit;
}

.call-out-heading-copy * {
	color: #fff;
}

.call-out-heading-copy p:last-child,
.bold-paragraph p:last-child {
	margin: 0px;
}

.video-section .div-block-27 {
	background-image: url("/local/public/shared/assets/images/websites/video-circle.png");
	background-repeat: no-repeat;
	background-position: top left;
	background-size: 42%;
	padding-top: 000px;
	margin: 0;
	margin-top: 30px;
	position: relative;
}

.video-section .div-block-27 .thumbnail {
	position: relative;
	width: 34vw;
	margin-right: 20px;
	margin-top: 4.7vw;
	border-radius: 50%;
}

.video-section .div-block-27 .more {
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 300;
	text-decoration: none;
	border: 0px;
	box-shadow: none;
	top: 0;
}


@media (max-width: 991px) {
	.div-block-22,
	.div-block-34 {
		margin-top: 0px;
		margin-bottom: 0px;
	}

	.div-block-22 {
		order: 2;
	}

	.div-block-34 {
		order: 1;
	}

	.div-block-22 {
		margin-top: 80px;
	}

	.call-out {
		margin-bottom: 80px;
	}

	.vsg-container {
		margin-bottom: 0px;
	}

	.video-section .div-block-27 {
		width: 100%;
	}

	.video-section .div-block-27 .thumbnail {
		position: relative;
		width: 50vw;
		margin-right: 0px;
		margin-top: 6.5vw;
		margin-left: 25px;
	}
}


@media (max-width: 767px) {
	.div-block-22 {
		margin-top: 20px;
	}

	.section-5 {
		margin-top: 80px;
	}

	.video-section .div-block-27 {
		background-image: none;
		margin-top: 0px;
		margin-bottom: 50px;
	}

	.video-section .div-block-27 .thumbnail {
		border-radius: 0px;
		margin-top: 0px;
		object-fit: cover;
		width: 100%;
		height: 40vw;
		margin-left: 0px;
	}
}

@media (max-width: 479px) {
	.div-block-22 {
		margin-top: 40px;
	}

	.video-section .div-block-27 {
		margin-bottom: 61px;
	}

	.video-section .div-block-27 .thumbnail {
		height: 57vw;
	}

	.yellow-circle {
		height: auto;
		padding: 15px 20px;
	}
}

/******************** Slideshow *********************/
/****************************************************/
.paragraph p {
	text-decoration: none;
	border-bottom: 0px;
	color: inherit;
	line-height: inherit;
	font-weight: inherit;
	font-size: inherit;
	font-family: inherit;
}

.w-slide .video-frame {
	width: 100%;
	height: 100%;
	background: url("/local/public/shared/assets/images/websites/play.png") center center no-repeat;
	cursor: pointer;
}

@media (max-width: 479px) {
	.w-slide .video-frame {
		background-position: center 50px;
	}
}
/******************** Inside page ********************/
/*****************************************************/
img {
	height: auto;
	max-width: 100%;
}

td img {
	max-width: none;
}

.breadcrumbs-list li:last-child .breadcrumb-divider {
	display: none;
}

.breadcrumbs-list li:last-child a {
	border-bottom: 0px;
}

.left-nav-link-3.active,
.left-nav-link-3.active:hover {
	background-color: rgba(0, 0, 0, 0.07);
	color: #4d5c79;
}

.with-left-sidebar .div-block-39 {
	width: calc(100% - 20% - 3vw);
}

.both-sidebars .div-block-39 {
	width: calc(100% - 40% - 6vw);
}

@media (max-width: 1248px) {
	.with-right-sidebar .div-block-39,
	.no-sidebars .div-block-39 {
		padding-left: 10px;
	}
}

@media (max-width: 991px) {
	.with-left-sidebar .div-block-39,
	.both-sidebars .div-block-39 {
		width: 100%;
	}

	.with-right-sidebar .div-block-39,
	.no-sidebars .div-block-39 {
		padding-left: 0px;
		width: 100%;
	}
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

@media (max-width: 479px) {
	.breadcrumbs-list {
		margin-top: 1rem;
		margin-bottom: 0.5rem;
	}
}


/*************** Right Sidebar ********************/
/*************************************************/
.right-side-col .box {
	margin-top: 20px;
	padding-bottom: 0.4rem;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.right-side-col .box:first-child {
	margin-top: 0px;
}

.right-side-col .box h4,
.right-side-col .box h3 {
	margin-top: 0px;
	color: #4d5c79;
	font-size: 18px;
	line-height: 24px;
	font-weight: 600;
}

.right-side-col .box td {
	vertical-align: top;
	padding-bottom: 12px;
}

.right-side-col .box .contentbox_item_image {
	width: 24px;
}

.right-side-col .box.documents td:first-child {
	padding-right: 7px;
}

.right-side-col .box.tools td:first-child img {
	margin-right: 5px;
}

@media (max-width: 991px) {
	.right-side-col .box {
		margin-left: 0em;
		padding-right: 0px;
		padding-left: 0px;
	}

	.right-side-col .box:first-child {
		margin-top: 20px;
	}
}


/****************** Footer ***********************/
/*************************************************/
.link-list-title,
.link-list-title-2 {
	text-transform: uppercase;
}

.section-3 a,
.section-4 a {
	border-bottom: 0px;
}

.copyright-info p {
	line-height: 70px;
}

.list-link-wrapper ul {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
	width: 100%;
}

.list-link-wrapper ul li {
	display: block;
	margin: 0px;
	padding: 0px;
	width: 100%;
}

.list-link-wrapper ul li a {
	display: inline-block;
	width: 100%;
	text-align: left;
}

#GRIPFooterLogo {
	padding-top: 0px !important;
}

#GRIPFooterLogo,
#GRIPFooterLogo a,
#GRIPFooterLogoText {
	line-height: 11px;
}

.div-block-10.contact .link-list-title {
	margin-right: 0px;
}

.div-block-10.quick-links .link-list-title-2 {
	text-align: left;
	padding-left: 0.35rem;
}

.phone-desktop {
	display: inline-block;
}

.phone-mobile {
	display: none;
}

.text-block-6 a:hover {
	color: #fff;
}

@media (max-width: 991px) {
	.div-block-10.contact .link-list-title {
		padding-left: 0px;
	}

	.phone-desktop {
		display: none;
	}

	.phone-mobile {
		display: inline-block;
	}
}

@media (max-width: 767px) {
	#GRIPFooterLogo {
		margin-bottom: 15px;
	}
}

@media (max-width: 479px) {
	.div-block-10.quick-links .link-list-title-2 {
		width: 100%;
		text-align: center;
	}

	.list-link-wrapper ul li a {
		text-align: center;
	}
}


/****************** Inside Page Styles ***************/
/*****************************************************/
/*pagination*/
.pagination-wrapper {
	font-size: 0.9rem;
	color: #333 !important;
}

.pagination-wrapper span {
	color: #333 !important;
	font-size: inherit;
}

h1 {
	margin-top: 0px;
	margin-bottom: 0px;
	margin-left: 0%;
	padding: 0px 0px 10px;
	border-radius: 20px;
	background-color: transparent;
	color: #4d5c79;
	font-size: 50px;
	line-height: 55px;
	font-weight: 700;
	text-transform: none;
}

h2,
h3,
h4,
h5,
h6 {
	color: #4d5c79;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #2b2954;
	text-decoration: underline;
	cursor: pointer;
	border-bottom: 0px;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	color: rgba(43, 41, 84, 0.45);
}

p {
	line-height: 22px;
}

a {
	border-bottom: 1px solid #2b2954;
	-webkit-transition: all 450ms ease;
	transition: all 450ms ease;
	color: #2b2954;
	line-height: 20px;
	font-weight: 500;
	text-decoration: none;
	cursor: pointer;
}

a:hover {
	border-bottom-style: solid;
	border-bottom-color: transparent;
	color: #4d5c79;
}

hr {
	height: 1px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	background-color: rgba(0, 0, 0, 0.15);
	border-bottom: 0px;
	border-top: 0px;
}

/* buttons */
.homeboxes a.primary,
.primary,
a.primary,
.cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
button[type=submit] {
	display: inline-block;
	min-width: 100px;
	margin-top: 16px;
	padding: 0.8rem 1rem 0.7rem;
	text-align: center;
	border-style: none;
	border-width: 2px;
	border-color: #6bb745;
	border-radius: 50px;
	background-color: #2b2954;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1rem;
	font-weight: 700;
	text-decoration: none;
	text-transform: none;
	cursor: pointer;
}

.homeboxes a.primary:hover,
.primary:hover,
a.primary:hover,
.cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
button[type=submit]:hover {
	border-style: none;
	border-width: 2px;
	background-color: #4d5c79;
	color: #fff;
}

.secondary,
a.secondary,
.homeboxes a.secondary,
.cms_form_button.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary {
	display: inline-block;
	min-width: 100px;
	margin-top: 16px;
	padding: 0.8rem 1rem 0.7rem;
	text-align: center;
	border-style: none;
	border-width: 2px;
	border-color: #4d5c79;
	border-radius: 50px;
	background-color: #4d5c79;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #fff;
	line-height: 1rem;
	font-weight: 700;
	text-decoration: none;
	text-transform: none;
	cursor: pointer;
}

.secondary:hover,
a.secondary:hover,
.homeboxes a.secondary:hover,
.cms_form_button.secondary:hover,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover {
	border-style: none;
	border-width: 2px;
	background-color: #707b8f;
	color: #fff;
}

.tertiary,
.cms_form_button.tertiary,
a.tertiary,
.homeboxes a.tertiary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary {
	font-family: 'Montserrat', sans-serif;
	border: 0;
	padding: 10px 20px;
	border-radius: 50px;
	text-transform: uppercase;
	color: #fff !important;
	background-color: #c77a48;
	display: inline-block;
	font-weight: 600;
	cursor: pointer;
	margin-top: 0px;
}

/* messages */
#message.success {
	background-color: #98e4a3;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #000;
}

#message.error {
	background-color: #f3baba;
	margin-top: 1rem;
	margin-bottom: 1rem;
	padding: 1rem 1rem 1rem 1.25rem;
	color: #000;
}

#message.error p:last-child,
#message.success p:last-child {
	margin-bottom: 0;
}

/* tables */
table.styled {
	margin: 0 0 1em;
	background-color: white;
	border-spacing: 0px;
}

table.styled th,
table.styled td {
	border-right: 1px solid #2e2e2e;
}

table.styled tr th:last-child,
table.styled tr td:last-child {
	border-right: none;
}

table.styled thead tr th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.625rem 0.625rem;
	text-align: left;
	background-color: #2b2954;
	color: white;
}

table.styled th {
	font-weight: inherit;
	font-size: 1rem;
	line-height: 1.2rem;
	padding: 0.5rem 0.625rem;
	text-align: left;
	background-color: #4d5c79;
	color: #ffffff;
}

table.styled th a,
table.styled th a:visited {
	color: inherit;
	font-weight: inherit;
	text-decoration: underline;
	border-bottom: 0px;
	background-color: transparent;
}

table.styled th a:hover {
	color: #fff;
	text-decoration: none;
}

table.styled td {
	padding: 0.375rem 0.625rem;
	font-size: 0.96rem;
	line-height: 1.4rem;
}

table.styled.striped tr:nth-child(even) {
	background: #ebeff2;
}

table.styled.striped tr:nth-child(odd) {
	background: white;
}

/* forms */
.main-content-outer-wrap input[type="text"],
.main-content-outer-wrap input[type="tel"],
.main-content-outer-wrap input[type="password"],
.main-content-outer-wrap input[type="email"],
.main-content-outer-wrap select,
.main-content-outer-wrap textarea,
.main-content-outer-wrap input[type="search"] {
	max-width: 100%;
	padding: 6px 12px;
	margin-bottom: 10px;
	font-size: 0.875rem;
	line-height: 1.428571429;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
}

.main-content-outer-wrap textarea {
	resize: vertical;
}

.main-content-outer-wrap label {
	font-weight: 400;
}

@media (max-width: 991px) {
	.main-content-outer-wrap input[type="text"],
	.main-content-outer-wrap input[type="tel"],
	.main-content-outer-wrap input[type="password"],
	.main-content-outer-wrap input[type="email"],
	.main-content-outer-wrap select,
	.main-content-outer-wrap textarea,
	.main-content-outer-wrap input[type="search"] {
		width: 100%;
	}
}

/*blockquotes*/
blockquote {
	margin-bottom: 1rem;
	padding: 1rem 1.5rem;
	border-left-width: 9px;
	border-left-color: #4d5c79;
	background-color: #e7e7e7;
	color: #404040;
	font-size: 1.35rem;
	line-height: 2rem;
}

blockquote p {
	font-size: inherit;
	line-height: inherit;
	margin-bottom: 0;
	margin-top: 0;
}

/************************ Modules ******************/
/*****************************************************/
/* accounts */
#account_module h2.form-title {
	margin-top: 2rem;
	margin-bottom: 2rem;
	font-size: 1.2rem;
	line-height: 1.5rem;
	color: #c77a48;
	font-style: italic;
}

#account_module.form .form_container .login_field {
	width: 100%;
	max-width: 243px;
	margin-bottom: 15px;
}

#account_module.form .more_options ul {
	width: 100%;
	text-align: center;
	padding-left: 0;
}

#account_module.form .form_subheading {
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	margin: 20px 0;
	padding-bottom: 4px;
	color: #2b2954;
	font-weight: bold;
}

/*edit address form*/
#account_module.form .ui-form-fields {
	margin-bottom: 10px;
}

#account_module.form .ui-form-label {
	margin-bottom: 3px;
}

#account_module.form .ui-form-label em {
	color: #ee0000;
}

@media (max-width: 991px) {
	#account_module.form .form_buttons {
		text-align: left;
	}

	#account_module.form tr.required .field {
		background: none;
		padding-left: 0;
		display: block;
		width: 100%;
	}

	#account_module.form .form_item .label,
	#account_module.form .form_item.required .label {
		display: inline-block;
		width: 100%;
		text-align: left;
		padding-bottom: 5px;
	}

	#account_module.form .form_item .label label,
	#account_module.form .form_item.required .label label {
		display: inline;
	}

	#account_module.form tr.required .label:after {
		content: url("/core/public/shared/assets/images/required.gif");
		padding-left: 10px;
	}

	#account_module.form .form_item .field {
		display: block;
		padding-left: 0;
	}

	#account_module.form .form_item .field .login_field {
		max-width: 100%;
	}

	#account_module.form .more_options ul {
		text-align: left;
	}
}

@media (max-width: 479px) {
	#account_module.form .ui-form-fields input[type="text"],
	#account_module.form .ui-form-fields select {
		width: 100% !important;
	}
}

/* events */
#fullcalendar-controls #categories .category-wrapper .category {
	top: 0px;
}

#events_module.cms_list .cms_list_item .cms_title {
	width: 100%;
}

#events_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0px;
}

#events_module.cms_list .cms_list_item .event-category-label {
	display: inline-block;
	margin-right: 4px;
}

#events_module.cms_list .cms_divider {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}


#events_module.cms_form .cms_label,
#events_module.cms_form .cms_field,
#events_module.cms_form .cms_required,
#events_module.cms_form .cms_non_required,
#events_module.cms_form .cms_td_answers {
	background-color: transparent;
	vertical-align: top;
}

#events_module.cms_form .cms_required {
	background-position: center 10px;
}

#events_module.cms_form h3 {
	margin-bottom: 0px;
}

#events_module.cms_form .cms_divider {
	margin-top: 5px !important;
	padding-top: 1px !important;
}

@media (max-width: 991px) {
	#events_module.cms_form .cms_field input[type="text"],
	#events_module.cms_form .cms_field input[type="password"],
	#events_module.cms_form .cms_field select,
	#events_module.cms_form .cms_field textarea,
	#events_module.cms_form .cms_field {
		display: block;
		clear: both;
		width: 100% !important;
		max-width: 100%;
	}

	#events_module.cms_form .cms_label {
		display: inline;
		text-align: left;
		padding-left: 0px;
		width: auto;
		float: left;
	}

	#events_module.cms_form .cms_required,
	#events_module.cms_form .cms_non_required {
		width: 20px;
		display: inline;
		text-align: left;
		padding-left: 0px;
		float: left;
	}
}

/* files */
.collection--files .node--file,
.collection--categories .node--category,
.collection.collection--files {
	margin: 1.5rem 0px 0px 0px;
	padding: 1.5rem 0px 0px 0px;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.collection--files .node--file p,
.collection--categories .node--category p,
.collection.collection--files p {
	margin-bottom: 0px;
	margin-top: 0px;
}

.collection.collection--files .bookmark.create {
	background-color: transparent;
	color: #6b7571;
	font-weight: 400;
}

.collection.collection--files .field--file--title a,
.collection.collection--categories .field--category--title a {
	padding-left: 0px;
	margin-left: 0px;
}

.collection.collection--files .field--file--title,
.collection.collection--files .field--category--title {
	background-position: 0 3px;
}

/* sidebar file filtering */
.right-side-col .box.filter .filter-row .select2-container,
.right-side-col .box.filter .filter-row #filter-keywords {
	max-width: 100%;
	width: 100% !important;
}

.right-side-col .box.filter .filter-row.ui-form-buttons {
	padding: 0px;
}

.right-side-col .box.filter .filter-row.ui-form-buttons .ui-form-field {
	padding: 6px;
	text-align: left;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices,
.right-side-col .box.filter .filter-row .select2-container .select2-choice {
	border-radius: 0px;
	box-shadow: none;
	border: 1px solid #cccccc;
	background: none;
	padding: 8px 12px;
	height: auto;
}

.right-side-col .box.filter .filter-row .select2-container .select2-choice > .select2-chosen {
	line-height: 1.2rem;
}

.right-side-col .box.filter .filter-row .select2-container-multi .select2-choices .select2-search-field .select2-input {
	margin: 0;
	padding: 0px;
}

/* forums */
#forums_module .image {
	margin-top: 0px;
	height: auto;
}

#forums_module #posts table,
#forums_module #boards table,
#forums_module #topics table,
#forums_module #posts .post_footer {
	background: none;
	border: 0px;
}

#forums_module #boards td,
#forums_module #topics td,
#forums_module #posts td {
	border-color: #ccc;
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
}

#forums_module #boards tr td:last-child,
#forums_module #topics tr td:last-child,
#forums_module #posts tr td:last-child,
#forums_module #topics th {
	border-color: #ccc;
}

#forums_module h2 {
	color: #002147;
	margin-top: 0px;
}

#forums_module #posts table td.actions a {
	height: auto;
	color: #002147;
}

#forums_module #topics tfoot tr th {
	padding-top: 1.5rem;
}

#forums_module.form.advanced-search .form_buttons {
	text-align: left;
}

#forums_module .pagination,
#forums_module .header-bottom,
#forums_module .header-top .header-right {
	font-size: 0.9rem;
}

#forums_module #posts .post_footer tr td.actions a,
#forums_module .header-top a,
#forums_module .header-bottom a {
	border-bottom: 0px;
}

#forums_module #boards tr:nth-child(1) td,
#forums_module #posts tr:nth-child(1) td,
#forums_module #topics tr:nth-child(1) td {
	padding-bottom: 1.5rem;
}

@media (min-width: 991px) {
	#forums_module.form .form_item .label,
	#forums_module.form .form_item.required .label {
		width: 15%;
	}
}

@media (max-width: 991px) {
	#forums_module #boards tr:nth-child(1) td,
	#forums_module #posts tr:nth-child(1) td,
	#forums_module #topics tr:nth-child(1) td {
		padding-top: 0px;
	}

	#forums_module #boards tr:nth-child(1) td:first-child {
		padding-bottom: 0px;
	}

	#forums_module #boards h2 {
		padding-top: 0.7rem;
	}


	#forums_module #topics tr td.poster_info,
	#forums_module #posts tr td.poster_info {
		padding-top: 1.5rem;
	}

	#forums_module #topics tr.moderator_actions td {
		padding-top: 0px;
		padding-bottom: 0px;
	}

	#forums_module #posts tfoot tr td {
		padding-top: 1.5rem;
	}

	#forums_module tr td small {
		font-size: 80%;
	}
}

/* faqs */
#faqs_module .cms_title h3 {
	margin-top: 0px;
}

#faqs_module .cms_list_item ul {
	padding-left: 0px;
	margin: 0px;
}

#faqs_module .cms_list_item ul li {
	padding-bottom: 7px;
}

#faqs_module.cms_list .cms_list_subitem {
	padding-bottom: 5px;
}

#faqs_module.cms_list .cms_item_divider,
#faqs_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

#faqs-search {
	margin-bottom: 0px;
}

/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-top: 1.5rem;
	margin-bottom: 1rem;
	border-top: 1px solid rgba(0, 0, 0, 0.15);
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image,
#news_module.cms_entity .cms_content .image {
	margin-bottom: 10px;
	margin-top: 0px;
	height: auto;
	max-width: 300px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

.box.archive .archive_links {
	overflow: hidden;
}

@media (max-width: 767px) {
	#news_module.cms_entity .cms_content .image {
		display: block;
		float: none;
		margin-left: 0px;
		height: auto;
		margin-bottom: 15px;
		margin-top: 15px;
	}
}


/* partners */
#partners_module.cms_list .cms_list_item .cms_title h3 {
	margin-top: 0rem;
}

#partners_module.cms_list .cms_list_item table td.image {
	padding-right: 25px;
	height: auto;
	margin-top: 0px;
}

#partners_module.cms_list .cms_list_item table td.image a img {
	max-width: 150px;
}

#partners_module.cms_entity .image {
	float: none;
	height: auto;
	margin-top: 0px;
	margin-right: 0px;
}

#partners_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#partners_module.cms_list .cms_list_item table td {
		display: block;
		width: 100%;
	}

	#partners_module.cms_list .cms_list_item table td.image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}
}

/* resources */

/* staff */
#staffdirectory_module.cms_list .cms_list_item .image {
	padding-right: 15px;
	height: auto;
	margin-top: 0px;
	width: 150px;
}

#staffdirectory_module.cms_entity .image {
	height: auto;
	margin-top: 0px;
}

#staffdirectory_module.cms_list .cms_list_item .image a img {
	max-width: 140px;
}

#staffdirectory_module.cms_list .cms_item_divider {
	border-top: 1px solid rgba(0, 0, 0, 0.15);
	margin-bottom: 1.5rem;
	margin-top: 1.5rem;
}

@media (max-width: 991px) {
	#staffdirectory_module.cms_list .cms_list_item .image {
		text-align: left;
		width: 100%;
		margin-bottom: 10px;
		padding-right: 0px;
	}

	#staffdirectory_module.cms_entity .image {
		float: none;
		margin-right: 0px;
		display: block;
	}
}

/* testimonials */
.no-sidebars .div-block-39.testimonials-feature {
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
}

#testimonials_module.cms_list {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

#testimonials_module.cms_list .cms_list_item {
	width: calc(33.33% - 16px);
	background-color: #e6e6e6;
	margin-top: 10px;
	margin-bottom: 26px;
	padding: 20px;
	display: flex;
	flex-direction: column;
}

#testimonials_module.cms_list .cms_list_item .thumbnail-image {
	text-align: left;
	display: flex;
	aspect-ratio: 16/8;
	align-items: center;
	overflow: hidden;
	max-width: 405px;
	margin-bottom: 7px;
}

#testimonials_module.cms_list .cms_list_item .thumbnail-image img {
	max-height: 100%;
	object-fit: cover;
	transition: 0.5s all ease;
	width: 100%;
	height: 100%;
}

#testimonials_module.cms_list .cms_list_item .testimonials-author-company {
	flex-grow: 1;
	display: flex;
	align-items: flex-end;
}

#testimonials_module.cms_list .cms_list_item .testimonials-more {
	color: #fff;
	text-transform: uppercase;
	background-color: #3d597e;
	border-radius: 50px;
	margin-top: 10px;
	padding: 0.75rem 1.5rem;
	font-family: Montserrat, sans-serif;
	font-weight: 700;
	font-size: 0.9rem;
	line-height: 1.4rem;
	text-transform: uppercase;
	display: inline-block;
}

#testimonials_module.cms_list .cms_list_item .testimonial-abstract {
	color: #000;
	margin-bottom: 0;
	font-family: Montserrat, sans-serif;
	font-weight: 500;
}

#testimonials_module.cms_list .cms_list_item .image-19 {
	height: 50px;
	margin-right: 10px;
}

#testimonials_module.cms_list .cms_list_item .testimonials-name {
	align-items: center;
	margin-top: 0;
	margin-bottom: 0;
	display: flex;
}

#testimonials_module.cms_list .cms_list_item .testimonials-name.first {
	margin-top: 10px;
}

#testimonials_module.cms_list .cms_list_item .testimonials-name h5 {
	margin-top: 0;
	margin-bottom: 0;
	font-family: Montserrat, sans-serif;
}

#testimonials_module.cms_list .cms_list_item .testimonials-name h5 strong {
	color: #c77a48;
	font-weight: 700;
}

@media (max-width: 991px) {
	.no-sidebars .div-block-39.testimonials-feature {
		width: 100%;
		padding-left: 0;
		padding-right: 0;
	}

	#testimonials_module.cms_list .cms_list_item {
		width: calc(50% - 16px);
	}
}

@media (max-width: 479px) {
	#testimonials_module.cms_list .cms_list_item {
		width: 100%;
		margin-bottom: 10px;
	}
}


/* submsission forms */
#submissionforms_module.cms_form .required_information em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_required em {
	color: #ee0000;
	font-family: inherit;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	padding-top: 2px;
	font-weight: normal;
	font-size: 0.9rem;
	color: #000;
	font-weight: bold;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .cms_label div {
	display: inline;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 8px 12px;
}

#submissionforms_module.cms_form .cms_field {
	padding-bottom: 15px;
}

#submissionforms_module.cms_form .checkbox_wrapper .item label,
#submissionforms_module.cms_form .radio_wrapper .item label {
	margin: 0px;
	font-size: 0.9rem;
	line-height: normal;
}

#submissionforms_module.cms_form .cms_hint,
#submissionforms_module.cms_form .cms_date_hint,
#submissionforms_module.cms_form .agreement_hint,
#submissionforms_module.cms_form .cms_field_note,
#submissionforms_module.cms_form input[type="file"],
#submissionforms_module.homepage_contentbox .cms_date_hint,
#submissionforms_module.homepage_contentbox .agreement_hint {
	font-size: 0.8rem;
	color: inherit;
}

#submissionforms_module.cms_form .signature_name {
	display: block;
}

#submissionforms_module.cms_form .sigWrapper.current {
	display: block !important;
}

/*posts */
.posts-collection > .posts-node {
	margin: 1.5rem 0 0 0;
	padding: 1.2rem 0 0 0;
}

.posts-collection .posts-node .posts-field h2 {
	margin-top: 0px;
}

.posts-node #post-comments .post-comments-title {
	background: #4d5c79;
}

.posts-node .posts-comments .ui-form-fieldset legend.ui-form-legend {
	font-size: 1rem;
}

.posts-node #post-comments .post-comment .post-comment-author {
	font-size: 0.8rem;
}

.posts-node #post-comments .post-comment {
	margin-top: 1rem;
	padding: 0px 0px 1rem 0px;
	border-bottom: 1px solid hsla(0, 0%, 45%, 0.11);
}

.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-textarea textarea,
.posts-node .posts-comments #post-add-comment .ui-form-fields .ui-form-input input[type="text"] {
	width: 100%;
}

#posts_module.form .form_item .ui-form-label,
#posts_module.form .form_item .required .ui-form-label {
	width: 100%;
	text-align: left;
}

.posts-node .required_information {
	color: #ee0000;
	font-weight: 400;
}

.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts,
.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-author,
.right-side-col .box.posts-blocks.posts-blocks-categories .posts-blocks-category,
.right-side-col .box.posts-blocks.posts-blocks-popular-posts .posts-blocks-popular-post {
	padding-bottom: 7px;
}

.right-side-col .box.posts-blocks.posts-blocks-authors .posts-blocks-posts {
	padding-left: 7px;
}

.right-side-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form input[type="text"].posts-blocks-search-field {
	height: 37px;
}

.right-side-col .posts-blocks.posts-blocks-searches .posts-blocks-search-form .posts-blocks-search-button {
	padding: 8px 11px 8px !important;
	min-width: auto;
}

.right-side-col .posts-blocks.posts-blocks-subscribes .posts-blocks-subscribe a,
.right-side-col .posts-blocks-rss-feed a {
	border-bottom: 0px;
}

/****************** Content Boxes ******************/
/*****************************************************/
/* events */
#events_module.homepage_contentbox {
	text-align: left;
}

#events_module.homepage_contentbox .date {
	width: 100px;
}

#events_module.homepage_contentbox .title {
	width: calc(100% - 100px);
}

#events_module.homepage_contentbox .event_list {
	margin-bottom: 15px;
}

/* files */

/* forumes */
#forums_module.homepage_contentbox h3 {
	margin: 0px;
	line-height: 1.2rem;
	font-size: 1.2rem;
}

#forums_module.homepage_contentbox .cms_list_item {
	margin-bottom: 20px;
}

/* news */
#posts_module.homepage_contentbox_homepage .cms_title h3 {
	margin: 0px;
	font-size: 1.3rem;
	line-height: 1.5rem;
}

#posts_module.homepage_contentbox_homepage .cms_date h3 {
	margin: 0px;
	font-size: 1rem;
	margin-bottom: 10px;
	line-height: 1.4rem;
}

/* partners */

/* testimonials */

/* form */
#submissionforms_module.homepage_contentbox table {
	width: auto;
	font-size: 0.9rem;
	text-align: left;
	line-height: 1.2rem;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	padding: 8px 12px;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #ccc;
	font-size: 0.9rem;
	margin-bottom: 10px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox .cms_field {
	padding-bottom: 5px;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox .sigWrapper .pad {
	max-width: 100%;
}

/*posts */

/******************** New redesign ********************/
/*****************************************************/
/* Header */
.accesible-navigation-menu li a.nav-link-a {
	text-transform: uppercase;
	font-family: 'Montserrat', sans-serif;
	font-size: 0.94em;
	padding-left: 20px;
	padding-right: 20px;
	text-align: center;
}

.slide-over-nav-copy.orange,
.slide-over-nav-copy.orange:hover {
	background-color: #c77a48;
	border-radius: 40px;
	color: #fff;
	text-transform: uppercase;
	padding: 17px 20px 15px 20px;
	text-decoration: none;
	border: 0px;
	box-shadow: none;
	border-bottom: 1px solid transparent;
	margin-top: 25px;
	font-size: 0.9rem;
	font-family: 'Montserrat', sans-serif;
	line-height: 0.9rem;
	letter-spacing: 1px;
}

.image {
	height: auto;
}

.accesible-navigation-menu li.slide-over-nav .container-wrapper ul {
	border-top: 5px solid #fff;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
	background-color: #fff;
	padding: 5px 0px;
}

.accesible-navigation-menu li.slide-over-nav .container-wrapper ul a {
	padding-top: 5px;
	padding-bottom: 5px;
	color: #4d5c79;
	font-size: 14px;
	font-weight: bold;
}

.accesible-navigation-menu li.slide-over-nav .container-wrapper ul a:hover,
.accesible-navigation-menu li.slide-over-nav.container-wrapper ul a.w--current {
	color: #c77a48;
}

@media (max-width: 1270px) and (min-width: 992px) {
	.container {
		padding-left: 40px;
		padding-right: 40px;
	}

	.accesible-navigation-menu li a.nav-link-a,
	.accesible-navigation-menu li.slide-over-nav.hasChildren a.nav-link-a {
		font-size: 0.85em;
		padding-left: 7px;
		padding-right: 7px !important;
		text-align: center;
	}

	.div-block-37 {
		padding-right: 40px;
	}
}

@media (min-width: 992px) {
	.accesible-navigation-menu li.hasChildren a.nav-link-a:after {
		content: "";
	}

	.accesible-navigation-menu li.slide-over-nav .container-wrapper {
		padding-top: 10px;
	}

	.accesible-navigation-menu li a.nav-link-a {
		padding-bottom: 10px;
		padding-top: 25px;
	}

	.accesible-navigation-menu li.slide-over-nav.hasChildren a.nav-link-a {
		padding-right: 20px;
	}

	.slide-over-nav:hover > a,
	.accesible-navigation-menu li a.nav-link-a.active {
		box-shadow: 0 5px 0 0 #c77a48;
	}

	.accesible-navigation-menu li.slide-over-nav .container-wrapper ul a {
		box-shadow: none;
	}

	.container {
		height: 75px;
		display: flex;
	}

	.nav-menu {
		margin-top: 0px !important;
	}

	.header-login-div span:last-child {
		display: none;
	}

	.header-login-div {
		margin-top: 26px;
	}
}

@media (max-width: 991px) {
	.w-nav-brand {
		margin-top: -20px;
	}

	.header-login-div {
		margin-top: 0px;
	}

	.icon-5 {
		font-size: 35px;
	}

	.menu-button {
		padding: 16px;
	}
}

@media (max-width: 767px) {
	.w-nav-brand {
		padding-left: 0;
		margin-top: -10px;
		width: auto;
		max-width: 60%;
	}

	.image {
		width: 240px;
	}

	.slide-over-nav-copy.orange,
	.slide-over-nav-copy.orange:hover {
		padding: 15px 15px 13px 15px;
		font-size: 0.75rem;
		line-height: 0.75rem;
	}
}

@media (max-width: 479px) {
	.w-nav-brand {
		padding-left: 10px;
		margin-top: -20px;
	}

	.slide-over-nav-copy.orange,
	.slide-over-nav-copy.orange:hover {
		padding: 10px 7px 7px 7px;
		font-size: 0.65rem;
		line-height: 0.65rem;
		border-radius: 13px;
		font-weight: 600;
		margin-right: 2px;
	}

	.image {
		width: 220px;
	}
}

/* Inside page */
.main-content-inner-wrap {
	margin-top: 150px;
}

/* Footer */
.image-17,
.image-18 {
	width: 40px;
	padding: 7px;
}

.social-icon,
.social-icon-end {
	display: inline-block;
}

.footer-logo {
	display: flex;
	justify-content: flex-end;
}

.footer-logo img {
	max-width: 250px;
}

.section-3,
.section-4 {
	background-color: #c77a48;
	padding-left: 85px;
	padding-right: 80px;
}

.section-3 > div {
	padding-right: 30px;
}

.section-3 > div:last-child {
	padding-right: 0px;
}

.text-block-6,
.footer-list-link,
.member-login-footer,
.link.phone-mobile,
.link.phone-desktop,
.member-login-footer:hover {
	color: #fff;
	font-size: 1rem;
	line-height: 1.5rem;
	font-family: 'Montserrat', sans-serif;
}

.member-login-footer {
	text-decoration: underline;
}

.footer-list-link,
.member-login-footer {
	font-weight: 600;
	text-transform: uppercase;
	font-family: 'Montserrat', sans-serif;
}

.footer-copyright {
	font-size: 0.8rem;
}

@media (max-width: 991px) {
	.section-3,
	.section-4 {
		padding-left: 35px;
		padding-right: 35px;
	}

	.copyright-info p {
		line-height: 1.3rem;
	}

	.footer-copyright {
		font-size: 0.8rem;
	}

	.text-block-6,
	.footer-list-link,
	.member-login-footer,
	.member-login-footer:hover .link.phone-mobile,
	.link.phone-desktop {
		font-size: 0.9rem !important;
		line-height: 1.4rem !important;
	}

	.footer-logo img {
		max-width: 240px;
	}
}

@media (max-width: 767px) {
	.section-3,
	.section-4,
	.footer-logo {
		display: block;
	}

	.section-3 > div,
	.list-link-wrapper,
	.text-block-6,
	.list-link-wrapper ul li a {
		text-align: center;
		padding-right: 0px;
		width: 100%;
	}

	.section-3 {
		padding-bottom: 20px;
	}

	.section-3 > div {
		margin-bottom: 20px;
	}

	.section-3 > div:last-child {
		margin-bottom: 0px;
	}

	.footer-logo img {
		max-width: 200px;
	}

	.copyright-info p {
		text-align: center;
	}
}

/* News Content Boxes */
#posts_module.homepage_contentbox_homepage {
	display: flex;
	width: 100%;
	color: #000;
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 20px;
	font-size: 0.85rem;
	line-height: 1.4rem;
	justify-content: center;
}

#posts_module.homepage_contentbox_homepage .post-item {
	width: 29%;
	border: 1px solid #a6a8aa;
	margin-right: 10px;
	margin-left: 10px;
}

#posts_module.homepage_contentbox_homepage .post-item .image {
	margin-bottom: 25px;
	height: 15vw;
	background-position: 50%;
	background-size: cover;
	display: inline-block;
	width: 100%;
	margin-top: 0px;
}

#posts_module.homepage_contentbox_homepage .post-item .title,
#posts_module.homepage_contentbox_homepage .post-item .description,
#posts_module.homepage_contentbox_homepage .post-item .date-more {
	padding-right: 35px;
	padding-left: 35px;
	padding-bottom: 15px;
	display: inline-block;
	width: 100%;
}

#posts_module.homepage_contentbox_homepage .post-item .title h3,
#posts_module.homepage_contentbox_homepage .post-item .title h3 a,
#posts_module.homepage_contentbox_homepage .post-item .title h3 a:hover {
	margin: 0px;
	color: #3d597a;
	font-size: 0.9rem;
	line-height: 1.3rem;
	font-weight: 700;
	text-decoration: none;
	border-bottom: 1px solid transparent;
}

#posts_module.homepage_contentbox_homepage .post-item .date-more .date {
	width: calc(100% - 110px);
	display: inline-block;
	float: left;
	font-size: 0.83rem;
}

#posts_module.homepage_contentbox_homepage .post-item .date-more .more {
	text-align: right;
	width: 110px;
	display: inline-block;
	float: left;
}

#posts_module.homepage_contentbox_homepage .post-item .date-more .more a {
	color: #c77a48;
	font-size: 0.8rem;
	line-height: 1rem;
	font-weight: 700;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	text-transform: uppercase;
}

@media (max-width: 991px) {
	#posts_module.homepage_contentbox_homepage {
		display: block;
	}

	#posts_module.homepage_contentbox_homepage .post-item {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 25px;
		max-width: 500px;
	}

	#posts_module.homepage_contentbox_homepage .post-item .image {
		height: 20vw;
	}
}

@media (max-width: 767px) {
	#posts_module.homepage_contentbox_homepage .post-item .image {
		height: 33vw;
	}
}

/* Testimonilas Content Boxes */
#testimonials_module_contentbox.contentbox_item .gallery {
	margin: 30px auto 0px auto;
	width: 100%;
}

#testimonials_module_contentbox.contentbox_item .gallery .slick-track {
	display: flex;
	height: 600px;
	justify-content: center;
	align-items: center;
	margin: 0px;
}

#testimonials_module_contentbox.contentbox_item .gallery-item {
	align-items: center;
	text-align: center;
	background-color: #dcb9a3;
	color: #7f583f;
	margin: 1vw;
	padding: 10px 1vw;
	height: 410px;
	opacity: 0.8;
	z-index: 1;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
	line-height: 1.5rem;
}

#testimonials_module_contentbox.contentbox_item .gallery-item.slick-current {
	background-color: #3d597a;
	color: #fff;
	opacity: 1;
	z-index: 2;
	transform: scale(1.2);
	transition: all 0.2s ease-in-out;
}

#testimonials_module_contentbox.contentbox_item .gallery-item.slick-current div {
	transform: scale(0.85);
	font-size: 0.9rem;
}

#testimonials_module_contentbox.contentbox_item .gallery-item a {
	transition: none;
}

#testimonials_module_contentbox.contentbox_item .gallery-item .author-name,
#testimonials_module_contentbox.contentbox_item .gallery-item .company {
	font-weight: bold;
}

#testimonials_module_contentbox.contentbox_item .gallery-item .description-box .additional {
	display: inline;
}

#testimonials_module_contentbox.contentbox_item .gallery-item .description-box .dots {
	display: none;
}

#testimonials_module_contentbox.contentbox_item .gallery-item .icon {
	background-image: url("/local/public/shared/assets/images/testimonials-slide-icon-grey.png");
	background-repeat: no-repeat;
	background-position: center;
	width: 70px;
	height: 70px;
	margin: 35px auto 0 auto;
	background-color: #e3bca3;
	border-radius: 100%;
	display: table;
}

#testimonials_module_contentbox.contentbox_item .gallery-item.slick-current .icon {
	background-image: url("/local/public/shared/assets/images/testimonials-slide-icon-blue.png");
	background-color: #fff;
}

#testimonials_module_contentbox.contentbox_item .gallery .slick-arrow {
	font-size: 0px;
	margin: 0px;
	background-color: transparent;
	padding-right: 0px;
	z-index: 1000;
	width: 70px;
	height: 70px;
	border-radius: 100%;
}

#testimonials_module_contentbox.contentbox_item .gallery .slick-arrow {
	position: absolute;
	top: 43%;
}

#testimonials_module_contentbox.contentbox_item .gallery .slick-arrow.slick-prev {
	left: -3%;
}

#testimonials_module_contentbox.contentbox_item .gallery .slick-arrow.slick-next {
	right: -3%;
}

#testimonials_module_contentbox.contentbox_item .gallery .slick-arrow:before {
	position: static;
	display: block;
	height: 6px;
	width: 6px;
	border: solid #fff;
	border-width: 0 6px 6px 0;
	display: inline-block;
	padding: 12px;
	margin-top: 14px;
	content: "";
}

#testimonials_module_contentbox.contentbox_item .gallery .slick-arrow.slick-prev:before {
	transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
	margin-right: 0px;
}

#testimonials_module_contentbox.contentbox_item .gallery .slick-arrow.slick-next:before {
	transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	margin-left: -22px;
}

#testimonials_module_contentbox.contentbox_item.default .testimonial-item {
	padding: 70px 30px 30px 30px;
	background-color: #c77a48;
	color: #fff;
	text-align: center;
	font-size: 1.1rem;
	line-height: 1.7rem;
	background-image: url("/local/public/shared/assets/images/testimonials-quote.png");
	background-repeat: no-repeat;
	background-position: center 20px;
}

#testimonials_module_contentbox.contentbox_item.default .testimonial-item .description {
	margin-bottom: 17px;
}

#testimonials_module_contentbox.contentbox_item.default .testimonial-item .author-name,
#testimonials_module_contentbox.contentbox_item.default .testimonial-item .company {
	font-weight: bold;
}

#testimonials_module_contentbox.contentbox_item.default .testimonial-item .image-box {
	margin-bottom: 10px;
}

#testimonials_module_contentbox.contentbox_item.default .testimonial-item .image-box img {
	max-width: 350px;
	height: auto;
	display: inline-block;
	margin: 0 auto;
}

@media (max-width: 1250px) {
	#testimonials_module_contentbox.contentbox_item .gallery-item .description-box .additional {
		display: none;
	}

	#testimonials_module_contentbox.contentbox_item .gallery-item .description-box .dots {
		display: inline;
	}
}

@media (max-width: 991px) {
	#testimonials_module_contentbox.contentbox_item .gallery-item {
		font-size: 0.9rem;
		line-height: 1.5rem;
	}

	#testimonials_module_contentbox.contentbox_item .gallery-item .icon {
		width: 60px;
		height: 60px;
	}

	#testimonials_module_contentbox.contentbox_item .gallery .slick-arrow {
		background-color: rgba(256, 256, 256, 0.3);
	}
}

@media (max-width: 767px) {
	#testimonials_module_contentbox.contentbox_item .gallery .slick-track {
		height: 530px;
	}

	#testimonials_module_contentbox.contentbox_item .gallery-item {
		height: 450px;
	}

	#testimonials_module_contentbox.contentbox_item .gallery-item {
		font-size: 0.85rem;
		line-height: 1.2rem;
	}

	#testimonials_module_contentbox.contentbox_item .gallery-item {
		font-size: 0.95rem;
		line-height: 1.6rem;
	}

	#testimonials_module_contentbox.contentbox_item .gallery-item.slick-current {
		transform: none;
	}

	#testimonials_module_contentbox.contentbox_item .gallery-item.slick-current div {
		transform: none;
	}

	#testimonials_module_contentbox.contentbox_item .gallery-item .description-box .additional {
		display: inline;
	}

	#testimonials_module_contentbox.contentbox_item .gallery-item .description-box .dots {
		display: none;
	}

	#testimonials_module_contentbox.contentbox_item .gallery .slick-arrow {
		position: absolute;
		top: 80%;
	}
}

@media (max-width: 479px) {
	#testimonials_module_contentbox.contentbox_item .gallery-item .description-box .additional {
		display: none;
	}

	#testimonials_module_contentbox.contentbox_item .gallery-item .description-box .dots {
		display: inline;
	}
}

/* Partners Content Boxes */
#partners_module.homepage_contentbox.displaymode_list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: center;
	width: 90%;
	margin: 30px auto 0 auto;
}

#partners_module.homepage-content-box.partners-carousel {
	margin-top: 40px;
}

#partners_module.homepage_contentbox.displaymode_list .cms_list_item {
	width: calc(20% - 30px);
	margin: 15px 15px 20px 15px;
}

#partners_module.homepage_contentbox.displaymode_list .cms_list_item a {
	border-bottom: 0px solid;
}

#partners_module.homepage_contentbox.displaymode_list .cms_list_item img {
	max-height: 85px;
}

@media (max-width: 991px) {
	#partners_module.homepage_contentbox.displaymode_list .cms_list_item {
		width: calc(50% - 30px);
	}
}

@media (max-width: 479px) {
	#partners_module.homepage_contentbox.displaymode_list .cms_list_item {
		width: 100%;
		margin-left: 0px;
		margin-right: 0px;
	}
}

/* Posts Content Boxes */
#posts-module.homepage-contentbox {
	display: flex;
	flex-wrap: wrap;
}

#posts-module.homepage-contentbox .padding {
	padding: 30px 25px 30px 25px;
}

#posts-module.homepage-contentbox .padding.orange {
	background-color: #c77a48;
}

#posts-module.homepage-contentbox .padding.blue {
	background-color: #3d597a;
}

#posts-module.homepage-contentbox .posts-box {
	width: calc(50% - 20px);
	margin-left: 10px;
	margin-right: 10px;
	background-color: #e6e6e6;
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
	min-height: 400px;
}

#posts-module.homepage-contentbox .posts-box.small {
	display: block;
	background-color: transparent;
	margin-right: 0px;
	margin-left: 0px;
	border-bottom: 1px solid #3d597a;
	padding-bottom: 1.8rem;
	margin-bottom: 1.8rem;
	min-height: auto;
	min-width: auto;
	width: 100%;
}

#posts-module.homepage-contentbox .posts-box.post-1 {
	padding-top: 60px;
}

#posts-module.homepage-contentbox .posts-box.post-1.featured-post {
	background-image: url("/local/modules/posts/assets/images/orange-ribbon.png");
	background-repeat: no-repeat;
	background-position: 90% top;
}

#posts-module.homepage-contentbox .posts-box.post-2 .padding.orange,
#posts-module.homepage-contentbox .posts-box.post-4 .padding.blue,
#posts-module.homepage-contentbox .posts-box.post-6 .padding.blue {
	flex-grow: 1;
}

#posts-module.homepage-contentbox .posts-box.post-2 .description.white {
	display: flex;
}

#posts-module.homepage-contentbox .posts-box.post-2 .description.white .post-image {
	width: 40%;
	margin-right: 2%;
	min-height: 8vw;
	background-position: 50%;
	background-size: cover;
}

#posts-module.homepage-contentbox .posts-box.post-2 .description.white .text-with-image {
	width: 58%;
}

#posts-module.homepage-contentbox .posts-box .thumbnail {
	height: 170px;
	width: 100%;
	background-size: cover;
	background-position: 50% 50%;
	margin-bottom: 15px;
	display: flex;
	flex-direction: column;
}

#posts-module.homepage-contentbox .posts-box .thumbnail.background {
	height: auto;
	flex-grow: 1;
	margin-bottom: 0px;
}

#posts-module.homepage-contentbox .posts-box .thumbnail.background a {
	height: 100%;
	width: 100%;
	display: inline-block;
}

#posts-module.homepage-contentbox .posts-box.post-3 .thumbnail.background .date,
#posts-module.homepage-contentbox .posts-box.post-5 .thumbnail.background .date {
	padding: 30px 25px 30px 25px;
}

#posts-module.homepage-contentbox .posts-box.small .title {
	color: #c77a48;
}

#posts-module.homepage-contentbox .posts-box.post-1 .content {
	margin-top: 5px;
	height: 90px;
	word-break: break-word;
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-moz-box-orient: vertical;
	-ms-box-orient: vertical;
	-webkit-line-clamp: 4;
	-moz-line-clamp: 4;
	-ms-line-clamp: 4;
	line-clamp: 4;
	overflow: hidden;
}

#posts-module.homepage-contentbox .posts-box .title a {
	font-weight: bold;
	font-size: 1rem;
	line-height: 1.4rem;
	text-decoration: none;
	border: 0px;
}

#posts-module.homepage-contentbox .posts-box .description p {
	font-size: 0.9rem;
	line-height: 1.5rem;
}

#posts-module.homepage-contentbox .posts-box .description.white p {
	color: #fff;
}

#posts-module.homepage-contentbox .posts-box .title.orange a {
	color: #c77a48;
}

#posts-module.homepage-contentbox .posts-box .title.white {
	margin-bottom: 5px;
}

#posts-module.homepage-contentbox .posts-box .title.white.desktop {
	display: block;
}

#posts-module.homepage-contentbox .posts-box .title.white.mobile {
	display: none;
}

#posts-module.homepage-contentbox .posts-box .title.white a {
	color: #fff;
}

#posts-module.homepage-contentbox .posts-box .date {
	font-size: 0.9rem;
	line-height: 1.5rem;
	text-align: right;
	margin-bottom: 15px;
}

#posts-module.homepage-contentbox .posts-box .date span {
	background-image: url("/local/modules/posts/assets/images/ic-clock.png");
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 18px 18px;
	min-height: 16px;
	padding-left: 28px;
}

#posts-module.homepage-contentbox .posts-box .date.white {
	color: #fff;
}

#posts-module.homepage-contentbox .posts-box .author {
	color: #c77a48;
	font-weight: bold;
	text-transform: uppercase;
	font-size: 0.9rem;
	line-height: 1.2rem;
}

#posts-module.homepage-contentbox .posts-box .author span {
	background-image: url("/local/modules/posts/assets/images/ic-author.png");
	background-repeat: no-repeat;
	background-position: left center;
	background-size: 30px;
	min-height: 30px;
	padding-left: 38px;
	display: flex;
	align-items: center;
}

#posts-module.homepage-contentbox .search-container {
	margin-bottom: 1.7rem;
}

#posts-module.homepage-contentbox .search-container input[type="text"] {
	width: 100%;
	border-radius: 20px;
	border: 1px solid #3d597a;
	padding: 10px 15px;
}


#posts-module.homepage-contentbox .search-container input[type="text"]::placeholder {
	color: #3d597a;
	font-weight: bold;
	text-transform: uppercase;
}

@media (max-width: 1270px) {
	#posts-module.homepage-contentbox .posts-box {
		width: calc(50% - 10px);
		margin-left: 5px;
		margin-right: 5px;
	}
}

@media (max-width: 991px) {
	#posts-module.homepage-contentbox .posts-box {
		width: calc(50% - 20px);
		margin-left: 10px;
		margin-right: 10px;
	}
}

@media (max-width: 767px) {
	#posts-module.homepage-contentbox {
		flex-wrap: wrap;
		justify-content: center;
	}

	#posts-module.homepage-contentbox .posts-box {
		width: calc(100% - 10%);
		margin-left: 5%;
		margin-right: 5%;
		margin-bottom: 20px;
		max-width: 500px;
	}

	#posts-module.homepage-contentbox .posts-box.post-1 .content {
		height: auto;
		word-break: break-word;
		-webkit-line-clamp: unset;
		-moz-line-clamp: unset;
		-ms-line-clamp: unset;
		line-clamp: unset;
	}

	#posts-module.homepage-contentbox .posts-box.post-2 .description.white {
		display: block;
	}

	#posts-module.homepage-contentbox .posts-box.post-2 .description.white .post-image {
		width: 100%;
		margin-right: 0;
		min-height: 25vw;
		margin-bottom: 5px;
	}

	#posts-module.homepage-contentbox .posts-box.post-2 .description.white .text-with-image {
		width: 100%;
	}

	#posts-module.homepage-contentbox .posts-box .title.white.desktop {
		display: none;
	}

	#posts-module.homepage-contentbox .posts-box .title.white.mobile {
		display: block;
	}
}

@media (max-width: 479px) {
	#posts-module.homepage-contentbox .posts-box {
		width: 90%;
		margin-left: 0px;
		margin-right: 0px;
	}
}


/* Jobs module */
#jobs_module.job_submit label,
#jobs_module.job_submit .ui-form-label span {
	font-size: 1rem;
	font-weight: bold;
}

#jobs_module.job_submit label.ui-checkbox-label {
	font-weight: normal;
}

#jobs_module.job_submit .ui-form-legend {
	background-color: #ffffff;
	padding: 1rem;
}

#jobs_module.job_submit .ui-form-legend h3 {
	color: #4d5c79;
}

#jobs_module.job_submit .ui-form-select > select {
	max-width: 348px;
}

#jobs_module.job_submit .ui-form-field td.checkbox {
	display: flex;
	align-items: center;
	column-gap: 0.5rem;
}

#jobs_module.job_submit .ui-form-field td.checkbox label {
	margin-bottom: 0;
}

#jobs_module.job_submit .ui-form-field .ui-form-field {
	padding-top: 0;
}
