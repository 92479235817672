/* All Homepages */
.homepage-container {
	font-family: 'Montserrat', sans-serif;
	margin-top: 148px;
}

.homepage-section {
	padding: 2rem 8%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.homepage-section iframe {
	max-width: 100%;
}

.homepage-section.grey {
	background-color: #efefef;
}

.homepage-section.orange {
	background-color: #c77a48;
}

.homepage-section.blue {
	background-color: #3d597a;
}

.homepage-section.blue.with-background {
	background-image: url("/assets/images/landing-pages/blue-background-section.png");
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 400px;
}

.homepage-section.carousel {
	background-image: url("/assets/images/landing-pages/carousel-section.webp");
	background-repeat: no-repeat;
	background-size: cover;
}

.homepage-section.join {
	max-width: 950px;
	margin: auto;
}

.hero-image {
	height: 400px;
	width: 100%;
	padding: 0px;
	position: relative;
	background-size: cover;
}

.hero-image.bottom {
	height: 510px;
}

.hero-image-inner.grey-background {
	background-color: rgba(0, 0, 0, 0.35);
	width: 100%;
	height: 100%;
	text-align: center;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hero-image-inner.blue-background {
	background-color: rgba(61, 89, 122, 0.65);
	width: 100%;
	height: 100%;
	text-align: center;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hero-image .hero-caption {
	color: #fff;
	min-width: 50%;
	max-width: 900px;
	padding: 10px;
}

.hero-image .hero-caption h2 {
	color: #fff;
	font-size: 2.4rem;
	line-height: 2.7rem;
	font-weight: normal;
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 20px;
	font-weight: 600;
}

.hero-image .hero-caption p {
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 20px;
	font-size: 1.15rem;
	line-height: 1.5rem;
	color: #fff;
}

.hero-video {
	height: 53vw;
	max-height: 600px;
	width: 100%;
	padding: 0px;
	position: relative;
	background-position: 50% 0%;
	background-size: cover;
}

.hero-video .video-frame {
	width: 100%;
	height: 100%;
	cursor: pointer;
	overflow: hidden;
}

.hero-video .video-frame video {
	width: 100%;
	height: auto;
}

.hero-video .video-caption {
	top: 20%;
	position: absolute;
	text-align: center;
	color: #fff;
	min-width: 40%;
	max-width: 760px;
	padding-left: 10px;
	padding-right: 10px;
	z-index: 100;
}

.hero-video .video-caption h2 {
	color: #fff;
	font-size: 3.7rem;
	line-height: 3.8rem;
	font-weight: normal;
	font-family: 'Times New Roman', serif;
	margin-bottom: 25px;
}

.hero-video .video-caption p {
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 20px;
	font-size: 1.15rem;
	line-height: 1.5rem;
	color: #fff;
}

.cb-title {
	font-family: 'Montserrat', sans-serif;
	font-size: 2rem;
	margin-top: 0px;
	font-weight: 600;
	line-height: 2.4rem;
}

.cb-title a,
.cb-title a:hover {
	text-decoration: none;
	border-bottom: 1px solid transparent;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
}

.cb-title.orange {
	color: #c77a48;
}

.cb-title.blue {
	color: #3d597a;
}

.cb-title.white {
	color: #fff;
}

.cb-text {
	color: #000;
	font-family: 'Montserrat', sans-serif;
	margin-bottom: 20px;
	font-size: 0.95rem;
	line-height: 1.7rem;
}

.cb-align-center {
	text-align: center;
}

.cb-align-left {
	text-align: left;
}

.cb-align-right {
	text-align: right;
}

.cb-text p {
	margin-bottom: 20px;
}

.cb-text p:last-child {
	margin-bottom: 0px;
}

.cb-text.white {
	color: #fff;
}

.cb-text p,
.cb-text li,
.cb-text span,
.cb-text td,
.cb-text a,
.cb-text a:hover,
.cb-text li {
	font-family: inherit;
	line-height: inherit;
	color: inherit;
	font-weight: inherit;
	font-size: inherit;
}

.cb-text a,
.cb-text a:hover {
	border-bottom: 1px solid transparent;
	text-decoration: none;
}

.cb-list ul {
	list-style-type: none;
	column-count: 3;
	text-align: left;
	list-style-image: url("/assets/images/landing-pages/icons/ic-list-check-box.png");
	margin-top: 30px;
}

.cb-list.orange ul {
	list-style-image: url("/assets/images/landing-pages/icons/ic-list-check-box-orange.png");
}

.cb-list.orange-bullets ul {
	list-style: none;
	margin-left: 30px;
}

.cb-list.orange-bullets ul li::before {
	content: "\2022";
	color: #c77a48;
	font-weight: bold;
	display: inline-block;
	width: 27px;
	line-height: 0;
	font-size: 30px;
	margin-left: -1em;
	position: relative;
	top: 4px;
}

.cb-list.x-icon ul {
	list-style-image: url("/assets/images/landing-pages/icons/ic-list-check-box-x.png");
}

.cb-list ul li {
	color: #778586;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.4rem;
	margin-bottom: 12px;
	font-size: 1rem;
	line-height: 1.5rem;
}

.cb-learn-more,
.cb-learn-more:hover {
	font-family: 'Montserrat', sans-serif;
	text-decoration: none;
	border-bottom: 1px solid transparent;
	padding: 10px 20px;
	border-radius: 50px;
	text-transform: uppercase;
	color: #fff !important;
	background-color: #666;
	display: inline-block;
	font-weight: 600 !important;
}

.cb-learn-more.blue,
.cb-learn-more.blue:hover {
	background-color: #3d597a;
}

.cb-learn-more.orange,
.cb-learn-more.orange:hover {
	background-color: #c77a48;
}

.cb-padding-left-767 {
	padding-left: 70px;
}

.cb-padding-right-767 {
	padding-right: 70px;
}

.cb-circle-container {
	display: inline-block;
	border-radius: 80%;
	padding: 5px;
	border: 1.9px dashed #778586;
	margin-bottom: 15px;
}

.cb-circle-container.no-border {
	border: 0px;
	width: 20%;
	margin-bottom: 0px;
}

.cb-circle {
	border-radius: 80%;
	background-color: #778586;
	height: 180px;
	width: 180px;
	display: flex;
	justify-content: center;
	align-items: center;
	display: flex;
	padding: 55px;
	align-items: center;
	justify-content: center;
}

.cb-circle.small {
	height: 80px;
	width: 80px;
	padding: 20px;
	border-radius: 100%;
	min-width: 80px;
}

.cb-circle a {
	text-decoration: none;
	border: 1px solid transparent;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.cb-circle img,
.cb-circle a img {
	max-height: 100%;
	max-width: 100%;
}

.cb-circle-container.orange {
	border: 1.9px dashed #c77a48;
}

.cb-circle-container.orange .cb-circle {
	background-color: #c77a48;
}

.cb-circle-container.blue {
	border: 1.9px dashed #3d597a;
}

.cb-circle-container.blue .cb-circle {
	background-color: #3d597a;
}

.cb-circle-container.no-border .cb-circle {
	background-color: #dcdcdc;
	padding: 30px 20px;
	margin: 0 auto 7px auto;
	height: 120px;
	width: 120px;
}

.cb-circle.blue {
	background-color: #3d597a !important;
}

.cb-flex-767,
.cb-flex-991 {
	display: flex;
	margin-top: 10px;
	margin-bottom: 10px;
}

.cb-flex-767.cb-align-right {
	justify-content: flex-end;
}

.cb-flex-767.cb-align-center {
	align-items: center;
}

.social-icon-end img {
	padding: 5px;
	width: 44px;
}

@media (max-width: 991px) {
	.homepage-container {
		margin-top: 137px;
	}

	.cb-col-991 {
		width: 100%;
		margin-bottom: 25px;
	}

	.cb-flex-991 {
		display: block;
	}

	.hero-video .video-caption {
		top: 15%;
	}

	.cb-list ul {
		column-count: 2;
	}

	.cb-circle-container.no-border .cb-circle {
		height: 100px;
		width: 100px;
		padding: 25px 15px;
	}
}

@media (max-width: 767px) {
	.homepage-container {
		margin-top: 131px;
	}

	.hero-video .video-caption {
		top: 5%;
	}

	.hero-video .video-caption h2,
	.hero-image .hero-caption h2 {
		font-size: 2.4rem;
		line-height: 2.8rem;
	}

	.hero-video .video-caption p,
	.hero-image .hero-caption p {
		font-size: 1.1rem;
		line-height: 1.4rem;
	}

	.cb-padding-left-767 {
		padding-left: 0px;
	}

	.cb-padding-right-767 {
		padding-right: 0px;
	}

	.cb-col-767 {
		width: 100%;
		margin-bottom: 25px;
	}

	.cb-list ul {
		column-count: 1;
	}

	.cb-circle-container.no-border {
		width: 33%;
	}

	.cb-flex-767 {
		display: block;
	}

	.cb-flex-767.cb-align-center {
		text-align: center;
	}
}

@media (max-width: 479px) {
	.homepage-container {
		margin-top: 108px;
	}

	.hero-video .video-caption h2,
	.hero-image .hero-caption h2 {
		font-size: 2.2rem;
		line-height: 2.4rem;
	}

	.hero-video .video-caption p,
	.hero-image .hero-caption p {
		font-size: 1rem;
		line-height: 1.3rem;
	}

	.cb-circle-container.no-border {
		width: 50%;
	}
}

/* Submission Form Content Box */
.homepage-section #submissionforms_module.homepage_contentbox {
	width: 100%;
	max-width: 800px;
	margin: 0 auto;
}

.homepage-section #submissionforms_module.homepage_contentbox .required_information {
	display: none;
}

.homepage-section #submissionforms_module.homepage_contentbox * {
	color: inherit;
}

.homepage-section #submissionforms_module.homepage_contentbox form table .cms_field {
	padding-bottom: 25px;
}

.homepage-section #submissionforms_module.homepage_contentbox table tr td input[type="text"],
.homepage-section #submissionforms_module.homepage_contentbox table tr td input[type="tel"],
.homepage-section #submissionforms_module.homepage_contentbox table tr td input[type="email"],
.homepage-section #submissionforms_module.homepage_contentbox table tr td select,
.homepage-section #submissionforms_module.homepage_contentbox table tr td textarea {
	padding: 10px 15px;
	font-size: 0.9rem;
	line-height: 0.9rem;
	color: #333;
	margin-bottom: 0px;
}

.homepage-section #submissionforms_module.homepage_contentbox table tr td input[type="text"]::placeholder,
.homepage-section #submissionforms_module.homepage_contentbox table tr td input[type="tel"]::placeholder,
.homepage-section #submissionforms_module.homepage_contentbox table tr td input[type="email"]::placeholder,
.homepage-section #submissionforms_module.homepage_contentbox table tr td select::placeholder,
.homepage-section #submissionforms_module.homepage_contentbox table tr td textarea::placeholder,
.homepage-section #submissionforms_module.homepage_contentbox table tr td select {
	text-transform: uppercase;
}

.homepage-section #submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary {
	font-family: 'Montserrat', sans-serif;
	border: 0;
	padding: 10px 20px;
	border-radius: 50px;
	text-transform: uppercase;
	color: #fff !important;
	background-color: #c77a48;
	display: inline-block;
	font-weight: 600;
	cursor: pointer;
	margin-top: 0px;
}

.homepage-section #submissionforms_module.homepage_contentbox .radio_wrapper td,
.homepage-section #submissionforms_module.homepage_contentbox .checkbox_wrapper td {
	vertical-align: top;
}

.homepage-section #submissionforms_module.homepage_contentbox form table .cms_label {
	font-weight: bold;
	font-size: 1.2rem;
	line-height: 1.7rem;
	padding-bottom: 20px;
}

.homepage-section #submissionforms_module.homepage_contentbox form table input[type="file"] {
	background: gray;
	border-radius: 10px;
	padding: 15px;
	margin-bottom: 7px;
}

@media (max-width: 767px) {
	.homepage-section #submissionforms_module.homepage_contentbox .cms_buttons {
		text-align: center;
	}
}

/* VMG Homepage */
.vmg-homepage .hero-video {
	background-image: url("/assets/images/landing-pages/homepage/hero-video-image.webp");
}

.vmg-homepage .hero-video:before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 1;
	background: rgba(61, 89, 122, 0.65);
}

.vmg-homepage .homepage-section.vmg {
	background-image: url("/assets/images/landing-pages/homepage/vmg-section.webp");
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: top right;
}

.vmg-homepage .homepage-section.vmg .what-is-vmg-video .videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.vmg-homepage .homepage-section.vmg .what-is-vmg-video .videoWrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}

.vmg-homepage .homepage-section.experience {
	padding-bottom: 160px;
}

.vmg-homepage .homepage-section.experience .cb-learn-more.orange {
	border-radius: 0px;
}

.vmg-homepage .homepage-section.circles {
	margin-top: -150px;
}

.vmg-homepage .homepage-section.circles > a {
	text-decoration: none;
	border-bottom: none;
	transition: none;
}

.vmg-homepage .homepage-section.circles .cb-text {
	width: 220px;
	color: #778586;
	margin: 0 auto 30px auto;
	font-weight: 600;
	font-size: 1.1rem;
	line-height: 1.8rem;
}

.vmg-homepage .homepage-section.circles .cb-text.orange {
	color: #c77a48;
}

.vmg-homepage .homepage-section.circles .cb-text.blue {
	color: #3d597a;
}

.vmg-homepage .homepage-section.sharing {
	padding-bottom: 0px;
	padding-top: 0px;
}

.vmg-homepage .homepage-section.sharing .cb-text {
	margin-bottom: 0px;
}

.vmg-homepage .homepage-section.why-me {
	padding-left: 0px;
	padding-bottom: 0px;
}

.vmg-homepage .homepage-section.why-me .why-me-image {
	background-repeat: no-repeat;
	background-size: contain;
	background-position: top left;
	background-image: url("/assets/images/landing-pages/homepage/why-me.webp");
	padding: 0;
	min-height: 740px;
	margin-top: -2rem;
}

.vmg-homepage .homepage-section.why-me h3 {
	font-family: 'Times New Roman', serif;
}

.vmg-homepage #testimonials_module_contentbox.contentbox_item .gallery-item .icon {
	display: none;
}

.vmg-homepage #testimonials_module_contentbox.contentbox_item .gallery-item {
	height: 480px;
}

@media (max-width: 991px) {
	.vmg-homepage .homepage-section.vmg {
		background-size: auto 60%;
		background-position: bottom;
	}

	.vmg-homepage .homepage-section.why-me {
		padding-bottom: 2rem;
	}

	.vmg-homepage .homepage-section.why-me .why-me-image {
		background-position: center;
		min-height: 600px;
		margin-top: 0px;
		margin-bottom: 30px;
	}
}

@media (max-width: 767px) {
	.vmg-homepage .homepage-section.circles {
		padding-top: 2rem;
		padding-bottom: 0;
	}

	.vmg-homepage .homepage-section.circles .cb-text {
		margin-bottom: 10px;
	}

	.vmg-homepage .homepage-section.why-me {
		padding-left: 6%;
		padding-bottom: 3rem;
	}

	.vmg-homepage .homepage-section.why-me div.cb-col-767:last-child,
	.vmg-homepage .homepage-section.why-me div.cb-col-767:last-child .cb-text {
		margin-bottom: 0px;
	}

	.vmg-homepage #testimonials_module_contentbox.contentbox_item .gallery-item {
		height: 410px;
		transform: none;
	}
}

/* VMG About Homepage */
.vmg-about-homepage .homepage-section.hero-image.top {
	background-image: url("/assets/images/landing-pages/homepage-about/hero-image.jpg");
}

.vmg-about-homepage .homepage-section.hero-image.bottom.become-member {
	background-position: 50%;
	background-image: url("/assets/images/landing-pages/homepage-about/become-member.jpg");
}

.vmg-about-homepage .homepage-section.about {
	background-image: url("/assets/images/landing-pages/homepage-about/about-section.png");
	background-repeat: no-repeat;
	background-size: auto 100%;
	background-position: top right;
}

.vmg-about-homepage .homepage-section.categories {
	padding-left: 12%;
	padding-right: 12%;
}

.vmg-about-homepage .homepage-section.categories .cb-list ul {
	display: inline-block;
}

.vmg-about-homepage .homepage-section.categories .cb-list ul li {
	margin-right: 5vw;
	margin-left: 5vw;
}

.vmg-about-homepage .homepage-section.staff {
	padding-bottom: 0px;
	padding-top: 0px;
}

.vmg-about-homepage .homepage-section.about .cb-flex-images {
	display: flex;
	flex-wrap: wrap;
}

.vmg-about-homepage .homepage-section.about .cb-flex-images .cb-flex-image {
	margin-bottom: 15px;
	background-position: 50%;
	background-size: cover;
	background-repeat: no-repeat;
	height: 260px;
}

.vmg-about-homepage .homepage-section.about .cb-flex-images .cb-flex-image.left {
	margin-right: 7px;
}

.vmg-about-homepage .homepage-section.about .cb-flex-images .cb-flex-image.right {
	margin-left: 7px;
}

.vmg-about-homepage .homepage-section.about-mission .cb-title.orange {
	font-weight: 800;
}

.vmg-about-homepage .homepage-section.our-mission-boxes {
	padding-right: 13%;
	padding-left: 13%;
}

.vmg-about-homepage .homepage-section.our-mission-boxes > div {
	background-image: url("/assets/images/landing-pages/homepage-about/our-mission-boxes.png");
	background-size: 100% 100%;
	height: 35vw;
	width: 100%;
	background-repeat: no-repeat;
	display: flex;
	flex-wrap: wrap;
}

.vmg-about-homepage .homepage-section.our-mission-boxes .cb-text {
	color: #fff;
	font-weight: 600;
	font-family: 'Montserrat', sans-serif;
	font-size: 1.4rem;
	line-height: 2.1rem;
	height: 17vw;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
}

.vmg-about-homepage .homepage-section.our-mission-boxes .cb-text p {
	max-width: 520px;
}

.vmg-about-homepage .homepage-section.timeline {
	padding-top: 1rem;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel {
	width: 90%;
	margin-left: 5%;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .item {
	height: 400px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-item:nth-child(even) .item {
	align-items: flex-end;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-item .item .item-inner {
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	min-height: 280px;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-item .item .item-inner .content {
	margin: 0 auto;
	max-width: 210px;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-item .item .item-inner .circle {
	height: 110px;
	width: 110px;
	background-color: #778586;
	text-align: center;
	color: #fff;
	font-size: 1.8rem;
	line-height: 2rem;
	font-weight: 600;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 100%;
	font-family: 'Montserrat', sans-serif;
	margin: 0 auto 12px auto;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-item .item.orange .item-inner .circle {
	background-color: #c77a48;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-item .item.grey .item-inner .circle {
	background-color: #778586;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-item .item.blue .item-inner .circle {
	background-color: #3d597a;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-item .item.black .item-inner .circle {
	background-color: #383838;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-item .item .item-inner .border {
	max-width: 170px;
	height: 1px;
	border-top: 1px dashed #666;
	width: calc(100% - 170px);
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-item.no-border .item .item-inner .border {
	border-top: 1px dashed transparent;
	max-width: 130px;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-nav button.owl-next,
.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-nav button.owl-prev {
	position: absolute;
	top: 40%;
	font-size: 80px;
	color: #3d597a;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-nav button.owl-next {
	right: 0%;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-nav button.owl-prev {
	left: -5%;
}

.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-nav button.owl-next.disabled,
.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-nav button.owl-prev.disabled {
	display: none;
}

@media (max-width: 991px) {
	.vmg-about-homepage .homepage-section.about {
		background-size: auto 60%;
		background-position: bottom;
	}

	.vmg-about-homepage .homepage-section.our-mission-boxes {
		padding-bottom: 0px;
	}

	.vmg-about-homepage .homepage-section.our-mission-boxes > div {
		background-image: none;
		height: auto;
	}

	.vmg-about-homepage .homepage-section.our-mission-boxes > div > div {
		display: flex;
	}

	.vmg-about-homepage .homepage-section.our-mission-boxes .cb-text {
		height: auto;
		font-size: 1.1rem;
		line-height: 1.6rem;
	}

	.vmg-about-homepage .homepage-section.our-mission-boxes .cb-text.blue {
		background-color: #3d597a;
		margin-right: 10px;
	}

	.vmg-about-homepage .homepage-section.our-mission-boxes .cb-text.orange {
		background-color: #c77a48;
		margin-left: 10px;
	}

	.vmg-about-homepage .homepage-section.our-mission-boxes .cb-text.black {
		background-color: #383838;
		margin-right: 10px;
	}

	.vmg-about-homepage .homepage-section.our-mission-boxes .cb-text.grey {
		background-color: #778586;
		margin-left: 10px;
	}
}

@media (max-width: 767px) {
	.vmg-about-homepage .homepage-section.timeline {
		padding-top: 0;
		padding-bottom: 0;
	}

	.vmg-about-homepage .homepage-section.timeline .owl-carousel .item {
		align-items: center;
	}

	.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-item:nth-child(even) .item {
		align-items: center;
	}

	.vmg-about-homepage .homepage-section.timeline .owl-carousel .owl-item .item .item-inner .border {
		display: none;
	}
}

@media (max-width: 479px) {
	.vmg-about-homepage .homepage-section.about .cb-flex-images .cb-flex-image {
		height: 200px;
	}

	.vmg-about-homepage .homepage-section.our-mission-boxes .cb-text.blue {
		margin-right: 0;
	}

	.vmg-about-homepage .homepage-section.our-mission-boxes .cb-text.orange {
		margin-left: 0;
	}

	.vmg-about-homepage .homepage-section.our-mission-boxes .cb-text.black {
		margin-right: 0;
	}

	.vmg-about-homepage .homepage-section.our-mission-boxes .cb-text.grey {
		margin-left: 0;
	}
}

/* VMG Membership Homepage */
.vmg-membership-homepage .homepage-section.categories {
	padding-left: 12%;
	padding-right: 12%;
}

.vmg-membership-homepage .homepage-section.categories .cb-list ul {
	display: inline-block;
}

.vmg-membership-homepage .homepage-section.categories .cb-list ul li {
	margin-right: 5vw;
	margin-left: 5vw;
}

.vmg-membership-homepage .homepage-section.hero-image.top {
	background-position: 50% 30%;
	background-image: url("/assets/images/landing-pages/homepage-membership/hero-image.jpg");
}

.vmg-membership-homepage .homepage-section.hero-image.bottom.first-step {
	background-position: 50%;
	background-image: url("/assets/images/landing-pages/homepage-membership/first-step.jpg");
}

.vmg-membership-homepage .homepage-section.circles .cb-circle-container {
	border: 3px dashed #778586;
	margin-top: 20px;
	margin-bottom: 20px;
}

.vmg-membership-homepage .homepage-section.circles .cb-circle-container.orange {
	border: 3px dashed #c77a48;
}

.vmg-membership-homepage .homepage-section.circles .cb-circle-container.blue {
	border: 3px dashed #3d597a;
}

.vmg-membership-homepage .homepage-section.circles .cb-circle {
	width: 250px;
	height: 250px;
	padding: 20px;
	flex-direction: column;
}

.vmg-membership-homepage .homepage-section.circles .cb-circle img {
	max-width: 70px;
}

.vmg-membership-homepage .homepage-section.circles .cb-text.white {
	color: #fff;
	font-weight: 600;
	font-size: 1rem;
	line-height: 1.4rem;
	margin-top: 20px;
	margin-bottom: 0px;
}

.vmg-membership-homepage .homepage-section.circles .cb-text.white.more {
	text-transform: uppercase;
	font-size: 0.9rem;
	line-height: 1rem;
}

.vmg-membership-homepage .homepage-section.circles .cb-text.white.more .arrow img {
	width: 18px;
	height: auto;
	margin-left: 5px;
	margin-top: -3px;
}

.vmg-membership-homepage .homepage-section.icons {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.vmg-membership-homepage .homepage-section.icons .cb-icon {
	width: 25%;
	text-align: center;
	margin-top: 40px;
}

.vmg-membership-homepage .homepage-section.icons .cb-icon .icon {
	border-radius: 100%;
	border: 1px dashed #3d597a;
	padding: 30px;
	width: 150px;
	height: 150px;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: center;
}

.vmg-membership-homepage .homepage-section.icons .cb-icon .icon img {
	max-height: 100%;
	width: auto;
}

.vmg-membership-homepage .homepage-section.icons .cb-icon .cb-text.blue {
	margin-bottom: 0px;
	margin-top: 10px;
	color: #3d597a;
	font-weight: bold;
	font-size: 1.2rem;
}

.vmg-membership-homepage .homepage-section.icons .cb-icon .cb-text.blue.header {
	font-size: 2.6rem;
	line-height: 2.8rem;
	font-weight: 600;
}


@media (max-width: 767px) {
	.vmg-membership-homepage .homepage-section.icons .cb-icon {
		width: 50%;
	}
}

@media (max-width: 479px) {
	.vmg-membership-homepage .homepage-section.icons .cb-icon {
		width: 100%;
	}
}

/* VMG Membership Homepage 1,2,3,4,5 */
.vmg-membership-homepage-inner .homepage-section.grey.with-background {
	background-image: url("/assets/images/landing-pages/homepage-membership/grey-background-section.png");
	background-repeat: no-repeat;
	background-size: auto 70%;
}

.vmg-membership-homepage-inner .homepage-section.orange-background {
	background-image: url("/assets/images/landing-pages/homepage-membership/orange-background-section.png");
	background-repeat: no-repeat;
	background-size: cover;
}

.vmg-membership-homepage-inner .homepage-section.hero-image.top {
	background-position: 50% 30%;
	background-image: url("/assets/images/landing-pages/homepage-membership/hero-image.jpg");
	margin-bottom: 9rem;
}

.vmg-membership-homepage-inner .homepage-section.circles .circles-inner {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	margin-top: -100px;
	padding-bottom: 0px;
}

.vmg-membership-homepage-inner .homepage-section.circles .cb-text {
	color: #778586;
	margin: 0 auto 0 auto;
	font-weight: bold;
	font-size: 1.1rem;
	line-height: 1.4rem;
	text-align: center;
}

.vmg-membership-homepage-inner .homepage-section.circles .cb-text.blue {
	color: #3d597a;
}

.vmg-membership-homepage-inner .cb-list ul {
	column-count: 1;
	margin-top: 13px;
}

.vmg-membership-homepage-inner .cb-list ul li {
	font-weight: normal;
	color: #000;
}

.vmg-membership-homepage-inner h2 {
	color: #c77a48;
	margin-bottom: 20px;
	font-weight: 600;
}

.vmg-membership-homepage-inner h3 {
	font-family: 'Times New Roman', serif;
}

.vmg-membership-homepage-inner h4 {
	color: #c77a48;
	font-size: 16px;
	line-height: 20px;
	text-transform: uppercase;
}

.vmg-membership-homepage-inner .cb-title .number {
	font-size: 3rem;
	line-height: 3rem;
}

.vmg-membership-homepage-inner .homepage-section.statistical-call-out .cb-circle-container {
	margin-right: 25px;
	margin-bottom: 0px;
}

.vmg-membership-homepage-inner .homepage-section.statistical-call-out .cb-align-right .cb-circle-container {
	margin-left: 25px;
}

.vmg-membership-homepage-inner .homepage-section.statistical-call-out .cb-circle {
	height: 130px;
	width: 130px;
	padding: 30px;
}

.vmg-membership-homepage-inner .homepage-section.statistical-call-out .cb-title {
	margin-bottom: 0px;
}

@media (max-width: 991px) {
	.vmg-membership-homepage-inner .homepage-section.circles .cb-text {
		font-size: 0.9rem;
		line-height: 1.2rem;
	}

	.vmg-membership-homepage-inner .homepage-section.grey.with-background {
		background-size: auto 30%;
	}

	.vmg-membership-homepage-inner .homepage-section.statistical-call-out .cb-circle-container,
	.vmg-membership-homepage-inner .homepage-section.statistical-call-out .cb-align-right .cb-circle-container {
		margin-left: 8px;
		margin-right: 8px;
	}
}

@media (max-width: 767px) {
	.vmg-membership-homepage-inner .homepage-section.statistical-call-out .cb-title {
		font-size: 1.6rem;
		line-height: 2rem;
	}

	.vmg-membership-homepage-inner .homepage-section.statistical-call-out .cb-circle-container,
	.vmg-membership-homepage-inner .homepage-section.statistical-call-out .cb-align-right .cb-circle-container {
		margin-left: 0px;
		margin-right: 0px;
	}
}

/* VMG Membership Homepage 1 */
.vmg-membership-homepage-inner.homepage-1 .homepage-section.intelectual-capital .cb-circle {
	margin-right: 20px;
}

.vmg-membership-homepage-inner.homepage-1 .homepage-section.intelectual-capital .cb-flex-767 {
	margin-top: 30px;
	margin-bottom: 0px;
}

.vmg-membership-homepage-inner.homepage-1 .homepage-section.intelectual-capital > div:first-child {
	margin-bottom: 30px;
}

@media (max-width: 991px) {
	.vmg-membership-homepage-inner.homepage-1 .homepage-section.intelectual-capital {
		padding-top: 0px;
	}
}

@media (max-width: 767px) {
	.vmg-membership-homepage-inner.homepage-1 .homepage-section.intelectual-capital .cb-flex-767 {
		margin-top: 10px;
	}
}

/* VMG Membership Homepage 2 */
.vmg-membership-homepage-inner.homepage-2 .homepage-section.financial-benchmarking .blue-grey-container {
	position: relative;
}

.vmg-membership-homepage-inner.homepage-2 .homepage-section.financial-benchmarking .blue-container {
	background-color: #4d5c79;
	padding: 30px 60px 00px 20px;
	margin-right: 10px;
}

.vmg-membership-homepage-inner.homepage-2 .homepage-section.financial-benchmarking .grey-container {
	background-color: #778586;
	padding: 30px 20px 0px 50px;
	margin-left: 10px;
}

.vmg-membership-homepage-inner.homepage-2 .homepage-section.financial-benchmarking h3 {
	font-family: 'Montserrat', sans-serif;
	font-size: 1.2rem;
	line-height: 1.4rem;
}

.vmg-membership-homepage-inner.homepage-2 .homepage-section.financial-benchmarking .vs-circle {
	background-color: #fff;
	border-radius: 100%;
	width: 110px;
	height: 110px;
	color: #c77a48;
	font-weight: bold;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	font-size: 2rem;
	justify-content: center;
	line-height: 2rem;
	position: absolute;
	left: 44%;
	top: 28%;
}

.vmg-membership-homepage-inner.homepage-2 .homepage-section.financial-benchmarking .blue-container ul li,
.vmg-membership-homepage-inner.homepage-2 .homepage-section.financial-benchmarking .grey-container ul li {
	color: #fff;
}

.vmg-membership-homepage-inner.homepage-2 .homepage-section.blue-circles .cb-circle {
	margin-right: 20px;
}

.vmg-membership-homepage-inner.homepage-2 .homepage-section.blue-circles .cb-list.performance ul {
	column-count: 2;
}

.vmg-membership-homepage-inner.homepage-2 .homepage-section.metrics .cb-list.orange-bullets.tracking ul {
	column-count: 2;
}

.vmg-membership-homepage-inner.homepage-2 .homepage-section.metrics .youtube-video iframe {
	margin-top: -100px;
}

.vmg-membership-homepage-inner.homepage-2 .homepage-section.grey.with-background {
	background-size: auto 110%;
}

.vmg-membership-homepage-inner.homepage-2 .homepage-section.blue-circles {
	background-image: url("/assets/images/landing-pages/homepage-membership/homepage-membership-2/blue-circles-section.png");
	background-repeat: no-repeat;
	background-size: auto 90%;
	background-position: top right;
}

@media (min-width: 992px) {
	.vmg-membership-homepage-inner.homepage-2 .homepage-section.blue-circles {
		padding-right: 0px;
	}

	.vmg-membership-homepage-inner.homepage-2 .homepage-section.blue-circles .financial-dashborad {
		text-align: right;
		align-self: center;
	}
}

@media (max-width: 991px) {
	.vmg-membership-homepage-inner.homepage-2 .homepage-section.financial-benchmarking .vs-circle {
		position: static;
		margin: -5px auto 20px auto;
	}

	.vmg-membership-homepage-inner.homepage-2 .homepage-section.financial-benchmarking .blue-container,
	.vmg-membership-homepage-inner.homepage-2 .homepage-section.financial-benchmarking .grey-container {
		padding: 30px 25px 1px 25px;
		margin-left: 0px;
		margin-right: 0px;
	}

	.vmg-membership-homepage-inner.homepage-2 .homepage-section.grey.with-background {
		background-size: auto 50%;
	}
}

@media (max-width: 767px) {
	.vmg-membership-homepage-inner.homepage-2 .homepage-section.metrics .youtube-video iframe {
		margin-top: 0px;
	}

	.vmg-membership-homepage-inner.homepage-2 .homepage-section.metrics .cb-list.orange-bullets.tracking ul {
		column-count: 1;
	}

	.vmg-membership-homepage-inner.homepage-2 .homepage-section.blue-circles {
		background-size: auto 40%;
	}
}

/* VMG Membership Homepage 3 */
.vmg-membership-homepage-inner.homepage-3 .homepage-section.professional-development .cb-circle {
	margin-right: 20px;
}

.vmg-membership-homepage-inner.homepage-3 .homepage-section.statistical-call-out .orange-circle-text {
	text-align: left;
}

@media (max-width: 767px) {
	.vmg-membership-homepage-inner.homepage-3 .homepage-section.statistical-call-out .orange-circle-text {
		text-align: center;
	}
}

/* VMG Membership Homepage 4 */
.vmg-membership-homepage-inner.homepage-4 .homepage-section.operational-support .cb-circle {
	margin-right: 20px;
}

.vmg-membership-homepage-inner.homepage-4 .homepage-section.operational-support {
	padding-bottom: 290px;
}

.vmg-membership-homepage-inner.homepage-4 .homepage-section.statistical-call-out .image-container {
	margin-top: -260px;
}

.vmg-membership-homepage-inner.homepage-4 .homepage-section.statistical-call-out .image-1 {
	background-image: url("/assets/images/landing-pages/homepage-membership/homepage-membership-4/statistical-call-out-image-1.jpg");
	background-size: cover;
	background-position: 50%;
	background-repeat: no-repeat;
	height: 450px;
	width: calc(100% - 15px);
	margin-right: 15px;
}

.vmg-membership-homepage-inner.homepage-4 .homepage-section.statistical-call-out .image-2 {
	background-image: url("/assets/images/landing-pages/homepage-membership/homepage-membership-4/statistical-call-out-image-2.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50%;
	height: 450px;
	width: calc(100% - 15px);
	margin-left: 15px;
}

@media (max-width: 991px) {
	.vmg-membership-homepage-inner.homepage-4 .homepage-section.statistical-call-out .image-1,
	.vmg-membership-homepage-inner.homepage-4 .homepage-section.statistical-call-out .image-2 {
		height: 340px;
	}
}

@media (max-width: 767px) {
	.vmg-membership-homepage-inner.homepage-4 .homepage-section.operational-support {
		padding-bottom: 240px;
	}

	.vmg-membership-homepage-inner.homepage-4 .homepage-section.statistical-call-out .image-1,
	.vmg-membership-homepage-inner.homepage-4 .homepage-section.statistical-call-out .image-2 {
		margin-left: 0px;
		margin-right: 0px;
		margin-bottom: 30px;
	}

	.vmg-membership-homepage-inner.homepage-4 .homepage-section.statistical-call-out .image-1,
	.vmg-membership-homepage-inner.homepage-4 .homepage-section.statistical-call-out .image-2 {
		height: 50vw;
	}
}

/* VMG Membership Homepage 5 */
.vmg-membership-homepage-inner.homepage-5 .homepage-section.purchasing-program .cb-circle {
	margin-right: 20px;
}

.vmg-membership-homepage-inner.homepage-5 .homepage-section.purchasing-program {
	padding-bottom: 270px;
}

.vmg-membership-homepage-inner.homepage-5 .homepage-section.statistical-call-out .image-container {
	margin-top: -260px;
	margin-bottom: 2.5rem;
}

.vmg-membership-homepage-inner.homepage-5 .homepage-section.statistical-call-out .image-1 {
	background-image: url("/assets/images/landing-pages/homepage-membership/homepage-membership-5/statistical-call-out-image-1.jpg");
	background-size: cover;
	background-position: 50%;
	background-repeat: no-repeat;
	height: 450px;
	width: calc(100% - 15px);
	margin-right: 15px;
}

.vmg-membership-homepage-inner.homepage-5 .homepage-section.statistical-call-out .image-2 {
	background-image: url("/assets/images/landing-pages/homepage-membership/homepage-membership-5/statistical-call-out-image-2.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: 50%;
	height: 450px;
	width: calc(100% - 15px);
	margin-left: 15px;
}

.vmg-membership-homepage-inner.homepage-5 .homepage-section.statistical-call-out .orange-circle-text {
	text-align: left;
}

@media (max-width: 991px) {
	.vmg-membership-homepage-inner.homepage-5 .homepage-section.statistical-call-out .image-1,
	.vmg-membership-homepage-inner.homepage-5 .homepage-section.statistical-call-out .image-2 {
		height: 340px;
	}
}

@media (max-width: 767px) {
	.vmg-membership-homepage-inner.homepage-5 .homepage-section.operational-support {
		padding-bottom: 240px;
	}

	.vmg-membership-homepage-inner.homepage-5 .homepage-section.statistical-call-out .image-1,
	.vmg-membership-homepage-inner.homepage-5 .homepage-section.statistical-call-out .image-2 {
		margin-left: 0px;
		margin-right: 0px;
		margin-bottom: 30px;
	}

	.vmg-membership-homepage-inner.homepage-5 .homepage-section.statistical-call-out .image-1,
	.vmg-membership-homepage-inner.homepage-5 .homepage-section.statistical-call-out .image-2 {
		height: 50vw;
	}

	.vmg-membership-homepage-inner.homepage-5 .homepage-section.statistical-call-out .orange-circle-text {
		text-align: center;
	}

	.vmg-membership-homepage-inner.homepage-5 .homepage-section.purchasing-program {
		padding-bottom: 240px;
	}
}

/* VMG Join Homepage */
.vmg-join-homepage .homepage-section.hero-image.top {
	background-position: 50% 30%;
	background-image: url("/assets/images/landing-pages/homepage-join/hero-image.jpg");
}

.vmg-join-homepage .homepage-section.get-started #submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary {
	width: 100%;
}

/* VMG Industry Insights Homepage */
.vmg-industry-insights-homepage .homepage-section.hero-image.top {
	background-position: 50% 30%;
	background-image: url("/assets/images/landing-pages/homepage-industry-insights/hero-image.jpg");
}

.homepage-section.posts_social_media {
	padding: 2rem 8%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.homepage-section__posts {
	max-width: 65%;
}

.homepage-section__social-media {
	max-width: 30%;
	margin-left: 10px;
}

.social-media-facebook {
	min-height: 400px;
}

.social-media-linkedin {
	margin-top: 20px;
}

.social-media-linkedin iframe {
	max-width: 100%;
	height: 400px !important;
	width: 500px;
}


.homepage-section__posts__readmore {
	width: 100%;
	margin: 0px auto 0px;
	text-align: center;
}

@media (max-width: 1270px) {
	.homepage-section.posts_social_media {
		padding: 2rem 0%;
		margin-left: 5px;
	}
}

@media screen and (max-width: 991px) {
	.homepage-section.posts_social_media {
		flex-direction: column-reverse;
	}

	.homepage-section__social-media {
		order: 3;
	}

	.homepage-section__posts {
		order: 2;
	}

	.homepage-section__posts__readmore {
		order: 1;
	}

	.homepage-section__posts {
		max-width: 100%;
	}

	.homepage-section__social-media {
		display: flex;
		max-width: 100%;
		margin-left: 0;
	}

	.homepage-section__social-media .social-media-facebook,
	.homepage-section__social-media .social-media-linkedin {
		width: calc(50% - 10px);
		margin-left: 10px;
		margin-right: 10px;
		margin-bottom: 20px;
	}

	.homepage-section__social-media .social-media-linkedin {
		margin-top: 0;
	}
}

@media (max-width: 767px) {
	.homepage-section__social-media {
		flex-wrap: wrap;
	}


	.homepage-section__social-media .social-media-facebook,
	.homepage-section__social-media .social-media-linkedin {
		width: calc(100% - 10%);
		margin-left: 5%;
		margin-right: 5%;
		margin-bottom: 20px;
		text-align: center;
	}
}

/* VMG Work Practice Homepage */
.vmg-work-practice-homepage .homepage-section.hero-image.top {
	background-position: 50% 50%;
	background-image: url("/assets/images/landing-pages/homepage-work-practice/hero-image.webp");
}

.vmg-work-practice-homepage h4 {
	color: #c77a48;
	font-size: 16px;
	line-height: 20px;
	text-transform: uppercase;
}

.vmg-work-practice-homepage .orange ul {
	column-count: 1;
	list-style-image: none;
	padding-left: 20px;
}

.vmg-work-practice-homepage .orange ul li {
	color: #4d5c79;
	background-image: url("/assets/images/landing-pages/icons/ic-list-check-box-orange.png");
	background-repeat: no-repeat;
	background-position: 0 3px;
	padding-left: 30px;
}

.vmg-work-practice-homepage .orange ul li::marker {
	margin-top: 5px;
	display: block;
	vertical-align: middle;
	padding-top: 5px;
}

.vmg-work-practice-homepage .homepage-section.why-choose {
	justify-content: space-between;
}

.vmg-work-practice-homepage .homepage-section.why-choose .image-background {
	background-repeat: no-repeat;
	background-position: 50% top;
	background-image: url("/assets/images/landing-pages/homepage-work-practice/why-choose.png");
	padding: 0;
	min-height: 594px;
	margin-top: -36px;
	max-width: 600px;
	border-bottom: 0 solid;
}

.vmg-work-practice-homepage .homepage-section.jobs-listing .cb-title-container {
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	gap: 3rem;
	flex-wrap: wrap;
}

.vmg-work-practice-homepage .homepage-section.jobs-listing .cb-title-container h2 {
	margin-bottom: 0;
}

.vmg-work-practice-homepage .homepage-section.jobs-listing p {
	padding-right: 15vw;
	line-height: 22px;
}

.vmg-work-practice-homepage .homepage-section.jobs-listing .job-opening {
	border-bottom: 1.5px solid #666;
	margin-bottom: 2rem;
	padding-bottom: 2rem;
}

.vmg-work-practice-homepage .homepage-section.jobs-listing .job-opening:last-child {
	border-bottom: 0px solid;
}

.vmg-work-practice-homepage .homepage-section.jobs-listing .job-opening h3 {
	color: #c77a48;
	font-size: 20px;
	line-height: 22px;
	margin-top: 0;
	margin-bottom: 5px;
}

.vmg-work-practice-homepage .homepage-section.jobs-listing .job-opening h3 a {
	line-height: 30px;
}

.vmg-work-practice-homepage .homepage-section.jobs-listing .job-opening h3 a:hover {
	border-bottom: 2px solid #c77a48;
}

.vmg-work-practice-homepage .homepage-section.jobs-listing .job-opening .job-opening-date {
	color: #3d597a;
	font-weight: bold;
	display: block;
	margin-bottom: 10px;
}

.vmg-work-practice-homepage .homepage-section.jobs-listing .job-listing-view-more a.cb-learn-more {
	padding-left: 4rem;
	padding-right: 4rem;
	margin-bottom: 0rem;
	margin-right: 1rem;
}

.vmg-work-practice-homepage .homepage-section.graduate-brochure {
	background: url("/assets/images/landing-pages/homepage-work-practice/downloadBrochure-bg.png") no-repeat;
	background-size: cover;
	padding-bottom: 4.6%;
}

.vmg-work-practice-homepage .homepage-section.graduate-brochure .graduate-brochure-image {
	display: block;
	margin: 0 auto;
	text-align: center;
}

.vmg-work-practice-homepage .homepage-section.graduate-brochure .graduate-brochure-image img {
	width: auto;
	height: auto;
	max-width: 50vw;
}

.vmg-work-practice-homepage .homepage-section.graduate-brochure .graduate-brochure-download {
	display: block;
	text-align: center;
	margin-top: 2rem;
}

.vmg-work-practice-homepage .homepage-section.graduate-brochure .graduate-brochure-download a.cb-learn-more {
	padding-left: 4rem;
	padding-right: 4rem;
}

.vmg-work-practice-homepage .homepage-section.join-vmg {
	background: url("/assets/images/landing-pages/homepage-work-practice/connect-with-us-bg.png") no-repeat;
	background-size: cover;
}

.vmg-work-practice-homepage .homepage-section.join-vmg #submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button.primary {
	width: 100%;
}

.vmg-work-practice-homepage .homepage-section.join-vmg .join-vmg-description {
	max-width: 800px;
	margin: 0 auto;
}

.vmg-work-practice-homepage .homepage-section.join-vmg .join-vmg-description p {
	color: #ffffff;
}

.vmg-work-practice-homepage .homepage-section.join-vmg .join-vmg-description h4 {
	color: #ffffff;
	font-size: 18px;
	text-align: left;
	margin-top: 4rem;
	text-transform: capitalize;
}

.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox table tbody {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1rem 0rem;
}

.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox table tbody tr {
	width: calc(50% - 0.5rem);
}

.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox table tbody tr[data-fieldtype="multiple-line-text"] {
	width: calc(100%);
}

.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox table tbody tr[data-fieldtype="file"] td {
	text-align: right;
}

.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox table tbody td {
	width: 100%;
	display: block;
}

.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox form table .cms_field {
	padding: 0;
}

.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox .required_information {
	display: none;
}

.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox * {
	color: inherit;
}

.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox table tr td input[type="text"],
.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox table tr td input[type="tel"],
.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox table tr td input[type="email"],
.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox table tr td select,
.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox table tr td textarea {
	padding: 20px 15px;
	font-size: 0.9rem;
	line-height: 0.9rem;
	color: #333;
	margin-bottom: 0px;
	width: 100% !important;
}

.vmg-work-practice-homepage .homepage-section.banner-image {
	margin-bottom: 5rem;
	flex-direction: column;
	align-items: center;
}

.vmg-work-practice-homepage .homepage-section.banner-image h2.cb-title.blue {
	margin-bottom: 2rem;
}

@media (max-width: 991px) {
	.vmg-work-practice-homepage .homepage-section.jobs-listing .cb-title-container {
		gap: 1.5rem;
		margin-bottom: 1.5rem;
		align-items: flex-start;
	}

	.vmg-work-practice-homepage .homepage-section.jobs-listing .cb-title-container h2 {
		width: 100%;
	}

	.vmg-work-practice-homepage .homepage-section.jobs-listing p {
		padding-right: 0;
	}

	.vmg-work-practice-homepage .homepage-section.jobs-listing .job-listing-view-more {
		text-align: center;
	}
}

@media (max-width: 767px) {
	.vmg-work-practice-homepage .homepage-section.why-choose .image-background {
		margin-bottom: 0px;
		min-height: 70vw;
		background-position: 50% 50%;
		background-size: cover;
	}

	.vmg-work-practice-homepage .homepage-section.jobs-listing .job-listing-view-more a.cb-learn-more {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.vmg-work-practice-homepage .homepage-section.graduate-brochure .graduate-brochure-download a.cb-learn-more {
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox table tbody {
		flex-direction: column;
	}

	.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox table tbody tr {
		width: 100%;
	}

	.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox table tbody tr:nth-child(19) td {
		text-align: unset;
	}

	.vmg-work-practice-homepage .homepage-section #submissionforms_module.homepage_contentbox table tbody tr:nth-child(20) td {
		text-align: unset;
	}
}

/* VMG Contact Us Homepage */
.vmg-contact-homepage .homepage-section.hero-image.top {
	background-position: 50% 62%;
	background-image: url("/assets/images/landing-pages/homepage-contact/hero-image.jpg");
	height: 28vw;
}

.vmg-contact-homepage .homepage-section.lets-talk-contact {
	background-image: url("/assets/images/landing-pages/homepage-contact/lets-talk-contact-section.png");
	background-repeat: no-repeat;
	background-size: auto 90%;
	background-position: top right;
}

.vmg-contact-homepage h3 {
	font-family: 'Times New Roman', serif;
}

.vmg-contact-homepage .homepage-section.lets-talk-contact .contact .contact-inner {
	background-color: #3d597a;
	padding: 2rem;
}

.vmg-contact-homepage .homepage-section.lets-talk-contact .lets-talk h3 {
	margin-bottom: 40px;
}

.vmg-contact-homepage .homepage-section.lets-talk-contact .lets-talk .map {
	margin-top: 40px;
	margin-bottom: 30px;
}

@media (max-width: 767px) {
	.vmg-contact-homepage .homepage-section.lets-talk-contact {
		background-size: auto 40%;
	}

	.vmg-contact-homepage .homepage-section.lets-talk-contact .lets-talk h3 {
		margin-bottom: 30px;
	}
}
